import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  InputAdornment,
  Link,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDropzone from "../../components/FileDropzone";
import TinymceEditor from "../../components/TinymceEditor";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Block from "../../components/Block";
import Separator from "../../components/Separator";
import CategorySelect from "../../components/AsyncSelects/CategorySelect";
import VariantConfigTable from "./VariantConfigTable";
import DatePicker from "../../components/DatePicker2";
import productsApi, {
  checkSkuAvailable,
  createProductOverride,
  deleteProductOverride,
  getProduct,
  getProductAccountsOverrides,
  getProductCustomerOverrides,
  getProductTerritoriesOverrides,
  updateProductOverride,
} from "../../distflowAPI/products";
import { updateProduct } from "../../redux/module/product";
import useAppDispatch from "../../hooks/useAppDispatch";

import HtmlTooltip from "../../components/HtmlToolTip";
import ProductOverrideAccordion from "./ProductOverrideAccordion";
import { SOLD_BY_OPTIONS } from "./consts";
import ImgPlaceholder from "../../images/img-placeholder.svg";
import ProductImageListItem from "./ProductImageListItem";
import ConfirmDialog from "../../components/ConfirmDialog";
import useFile from "../../hooks/useFile";
import { fireErrorNotification, fireSuccessNotification } from "../../utils";
import { createTag } from "../../redux/module/tag";
import TagSelect from "../../components/AsyncSelects/TagSelect";
import VendorSelect from "../../components/AsyncSelects/VendorSelect";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";
import CustomerSelect from "../../components/AsyncSelects/CustomerSelect";
import TerritorySelect from "../../components/AsyncSelects/TerritorySelect";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { DATE_FORMAT } from "../../utils/constants";
import ProductOrdersHistory from "./ProductOrdersHistory";
import useAppSelector from "../../hooks/useAppSelector";

const UpdateProduct = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isFileValid } = useFile();
  const { productOrdersHistoryLoading } = useAppSelector(
    (state) => state.product
  );

  const [images, setImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    label: "",
    value: null,
  });
  const [selectedTag, setSelectedTag] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState({
    label: "",
    value: null,
  });
  const { id } = useParams();
  const [currImages, setCurrImages] = useState([]);
  const [tagSearch, setTagSearch] = useState("");

  const [name, setName] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [category, setCategory] = useState(null);
  const [errors, setErrors] = useState({});
  const [description, setDescription] = useState("");
  const [deliveryType, setDeliveryType] = useState("");
  const [variants, setVariants] = useState({});
  const [variantsNo, setVariantsNo] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [internalId, setInternalId] = useState("");
  const [upcharges, setUpcharges] = useState({
    global_upcharge: 0,
    vendor_upcharge: 0,
    category_upcharge: 0,
  });

  const [isProductLoading, setIsProductLoading] = useState(false);
  const [isProductOverridesLoading, setIsProductOverridesLoading] =
    useState(false);

  function addVariant(variant) {
    const newNo = variantsNo + 1;
    setVariantsNo(newNo);
    setVariants({ ...variants, [newNo]: variant });
  }

  const soldByValMapper = {
    PC: "Piece",
    BX: "Box",
    BK: "Bucket",
    BT: "Batter",
    CT: "Container",
    DZ: "Dozen",
    EA: "Each",
    LB: "Lb",
    PK: "Package",
    PL: "pale / bucket",
    SH: "Sheet",
  };

  function fetchProduct() {
    setIsProductLoading(true);
    getProduct(id)
      .then((res) => {
        console.log("res", res.data);
        setName(res.data.name);
        setAdditionalInfo(res.data.additional_info);
        setSelectedCategory({
          value: res.data?.category?.id,
          label: res.data?.category?.name,
        });
        setCategory(res.data.category.id);
        setSelectedVendor({
          value: res.data.vendor.id,
          label: res.data.vendor.name,
        });
        setDescription(res.data.description);
        setDeliveryType(res.data.delivery_type);
        setIsActive(res.data.is_active);
        setSelectedTag(
          res.data.tags.map((item) => ({ value: item.id, label: item.name }))
        );
        setIsHidden(res.data?.hidden);
        setCurrImages(res.data.images);
        setInternalId(res.data.internal_id || "");
        setUpcharges({
          global_upcharge: res.data.global_upcharge,
          vendor_upcharge: res.data.vendor_upcharge,
          category_upcharge: res.data.category_upcharge,
        });
        const vars = {};
        res.data.variants.map((item) => {
          vars[item.id] = {
            name: item.name,
            sku: item.sku,
            cost: item.cost,
            images: item.images,
            barcode: item.barcode,
            sold_by: {
              value: item.sold_by,
              label: soldByValMapper[item.sold_by],
            },
            count: item.count,
            variantId: item.id,
            per_box: item.per_box,
            per_pack: item.per_pack,
            order_qty: item.order_qty,
            code: item.code,
            weight: item.weight,
            weight_2: item.weight_2,
            sheet: item.sheet,
            overrides_count: item.overrides_count,
            price: item.price,
            sold_by_qty: item.sold_by_qty,
            break_into_pieces: item.break_into_pieces,
            piece_min_qty: item.piece_min_qty,
            break_into_pieces_sp: item.break_into_pieces_sp,
            overrides: {
              category: item.category_upcharge?.value || 0,
              vendor: item.vendor_upcharge?.value || 0,
              global: item.global_upcharge || 0,
              variant: item.self_upcharge?.value || 0,
            },
          };
        });
        const variantIds = Object.keys(vars)
          .map((id) => Number(id))
          .sort((a, b) => (a > b ? 1 : -1));
        setVariantsNo(variantIds[variantIds.length - 1] + 1);
        setVariants(vars);
      })
      .finally(() => {
        setIsProductLoading(false);
      });
  }

  useEffect(() => fetchProduct(), []);

  async function handleSubmit() {
    setErrors({});
    const newErrors = {};
    const required_fields = {
      name,
      category: selectedCategory,
      vendor: selectedVendor,
      description,
      delivery_type: deliveryType,
      variants,
    };
    for (const key in required_fields) {
      if (!required_fields[key]) {
        newErrors[key] = `${key} can not be empty`;
        setErrors(newErrors);
        return;
      }
    }
    const variants_required_fields = ["name", "sku", "cost"];
    for (const key in variants) {
      const requeredEmptyField = variants_required_fields.find((field) => {
        if (!variants[key][field]) {
          return true;
        }
      });
      if (requeredEmptyField) {
        newErrors[`${key}${requeredEmptyField}`] =
          `${requeredEmptyField} can not be empty`;
        setErrors(newErrors);
        return;
      }
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("additional_info", additionalInfo);
    formData.append("form_category", selectedCategory.value);
    if (selectedTag) {
      selectedTag.map((item) => formData.append("form_tags", item.value));
    }
    formData.append("form_vendor", selectedVendor.value);
    formData.append("description", description);
    formData.append("delivery_type", deliveryType);
    formData.append("is_active", isActive);
    formData.append("hidden", isHidden);
    formData.append("internal_id", internalId);
    if (images) {
      for (let im = 0; im < images.length; im++) {
        formData.append("form_images", images[im], images[im].name);
      }
    }

    Object.values(variants).map((variant, cc) => {
      formData.append(`form_variants_update[${cc}]name`, variant.name);
      formData.append(`form_variants_update[${cc}]sku`, variant.sku);
      formData.append(`form_variants_update[${cc}]cost`, variant.cost);
      formData.append(`form_variants_update[${cc}]barcode`, variant.barcode);

      formData.append(
        `form_variants_update[${cc}]sold_by_qty`,
        variant.sold_by_qty
      );
      formData.append(
        `form_variants_update[${cc}]break_into_pieces`,
        variant.break_into_pieces
      );
      formData.append(
        `form_variants_update[${cc}]piece_min_qty`,
        variant.piece_min_qty
      );
      formData.append(
        `form_variants_update[${cc}]break_into_pieces_sp`,
        variant.break_into_pieces_sp
      );

      formData.append(
        `form_variants_update[${cc}]sold_by`,
        variant.sold_by.value.toUpperCase()
      );
      formData.append(`form_variants_update[${cc}]per_box`, variant.per_box);
      formData.append(`form_variants_update[${cc}]per_pack`, variant.per_pack);
      formData.append(`form_variants_update[${cc}]price`, variant.price);

      formData.append(
        `form_variants_update[${cc}]order_qty`,
        variant.order_qty || 0
      );
      formData.append(`form_variants_update[${cc}]code`, variant.code);
      formData.append(`form_variants_update[${cc}]sheet`, variant.sheet);

      if (
        variant.image &&
        Array.isArray(variant.image) &&
        variant.image.length > 0 &&
        variant.image[0].size > 0
      ) {
        formData.append(
          `form_variants_update[${cc}]form_images`,
          variant.image[0],
          variant.image[0].name
        );
      }
    });
    await dispatch(updateProduct({ id, data: formData })).unwrap();
    await fetchProduct(); // fetch product for update image value
    setImages([]); // clean image state because all images from backend in currImages
    //navigate(-1);
  }

  const [overrideType, setOverrideType] = useState("account");
  const [override, setOverride] = useState(0);

  const initialOverrideSelectData = {
    value: null,
    label: "",
  };
  const [selectedAccountOverride, setSelectedAccountOverride] = useState(
    initialOverrideSelectData
  );
  const [selectedCustomerOverride, setSelectedCustomerOverride] = useState(
    initialOverrideSelectData
  );
  const [selectedTerritoryOverride, setSelectedTerritoryOverride] = useState(
    initialOverrideSelectData
  );
  const [overrideSubmitting, setOverrideSubmitting] = useState(false);
  const [overrideError, setOverrideError] = useState({});
  const [overrideSuccess, setOverrideSuccess] = useState("");
  const [overrideStartDate, setOverrideStartDate] = useState();
  const [overrideEndDate, setOverrideEndDate] = useState();

  function createOverride(
    id,
    payload,
    nextFunc,
    selectedOverride,
    elementName
  ) {
    console.log("CREATING OVERRIDE: ", {
      id,
      payload,
      nextFunc,
      selectedOverride,
      elementName,
    });
    if (!selectedOverride) {
      setOverrideError({ [elementName]: ["Select a " + elementName] });
      setOverrideSubmitting(false);
      return;
    }
    payload[elementName] = selectedOverride.value;
    createProductOverride(id, payload).then(
      () => {
        setOverrideSuccess(elementName + " override created!");
        nextFunc();
      },
      (error) => setOverrideError(error.response.data)
    );
  }

  const cleanProductOverrideForm = () => {
    setOverride(0);
    setOverrideStartDate(null);
    setOverrideEndDate(null);
    setOverrideSubmitting(false);
    setSelectedTerritoryOverride(initialOverrideSelectData);
    setSelectedAccountOverride(initialOverrideSelectData);
    setSelectedCustomerOverride(initialOverrideSelectData);
  };

  async function submitOverride(event) {
    setOverrideError({});
    setOverrideSuccess("");
    event.preventDefault();
    if (!override) {
      setOverrideError({ override: ["Input a valid override"] });
      setOverrideSubmitting(false);
      return;
    }
    setOverrideSubmitting(true);
    const start_date = overrideStartDate?.isValid()
      ? overrideStartDate?.format(DATE_FORMAT)
      : null;
    const end_date = overrideEndDate?.isValid()
      ? overrideEndDate?.format(DATE_FORMAT)
      : null;
    const payload = {
      tp: overrideType,
      override,
      start_date,
      end_date,
      product: id,
    };
    if (overrideType == "customer") {
      await createOverride(
        id,
        payload,
        fetchCustomerOverrides,
        selectedCustomerOverride,
        "customer"
      );
    }
    if (overrideType == "account") {
      await createOverride(
        id,
        payload,
        fetchAccountOverrides,
        selectedAccountOverride,
        "account"
      );
    }
    if (overrideType == "territory") {
      await createOverride(
        id,
        payload,
        fetchTerritoriesOverrides,
        selectedTerritoryOverride,
        "territory"
      );
    }
    cleanProductOverrideForm();
    //append created data to list
  }

  const [currentAccountsOverride, setCurrentAccountsOverrides] = useState({});
  const [currentCustomersOverrides, setCurrentCustomersOverrides] = useState(
    {}
  );
  const [currentTerritoriesOverrides, setCurrentTerritoriesOverrides] =
    useState({});

  function normalizeOverridesResponse(overrides, key) {
    const results = {};
    overrides.map((item) => {
      if (results[item[key]]) {
        results[item[key]]["items"].push(item);
      } else {
        results[item[key]] = {
          [`${key}_name`]: item[`${key}_name`],
          items: [item],
        };
      }
    });
    return results;
  }

  function fetchAccountOverrides() {
    getProductAccountsOverrides(id).then((res) =>
      setCurrentAccountsOverrides(
        normalizeOverridesResponse(res.results, "account")
      )
    );
  }

  function fetchCustomerOverrides() {
    getProductCustomerOverrides(id).then((res) =>
      setCurrentCustomersOverrides(
        normalizeOverridesResponse(res.results, "customer")
      )
    );
  }

  function fetchTerritoriesOverrides() {
    getProductTerritoriesOverrides(id).then((res) =>
      setCurrentTerritoriesOverrides(
        normalizeOverridesResponse(res.results, "territory")
      )
    );
  }

  useEffect(() => {
    async function fetchData() {
      try {
        setIsProductOverridesLoading(true);

        const [accountsRes, customersRes, territoriesRes] = await Promise.all([
          getProductAccountsOverrides(id),
          getProductCustomerOverrides(id),
          getProductTerritoriesOverrides(id),
        ]);

        setCurrentAccountsOverrides(
          normalizeOverridesResponse(accountsRes.results, "account")
        );
        setCurrentCustomersOverrides(
          normalizeOverridesResponse(customersRes.results, "customer")
        );
        setCurrentTerritoriesOverrides(
          normalizeOverridesResponse(territoriesRes.results, "territory")
        );

        setIsProductOverridesLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsProductOverridesLoading(false);
      }
    }

    fetchData();
  }, []);

  const [variantsErrors, setVariantsErrors] = useState({});

  const checkSku = async (sku) => {
    try {
      const available = await checkSkuAvailable(sku);
      const newErrors = { ...variantsErrors };
      if (!available) {
        newErrors.sku = `SKU "${sku}" Already exists`;
        setVariantsErrors(newErrors);
      } else if (newErrors.sku) {
        delete newErrors.sku;
        setVariantsErrors(newErrors);
      }
      for (const key in variants) {
        if (variants[key].sku === sku) {
          newErrors.sku = `SKU "${sku}" Already exists`;
          setVariantsErrors(newErrors);
          return false;
        }
      }
      return available;
    } catch (e) {
      // Обработка ошибки
    }
  };

  const [variantImage, setVariantImage] = useState();
  const [variantImageUrl, setVariantImageUrl] = useState();
  const variantImageRef = useRef(null);
  const [variantName, setVariantName] = useState("");
  const [variantSKU, setVariantSKU] = useState("");
  const [variantBarCode, setVariantBarcode] = useState("");
  const [variantCode, setVariantCode] = useState("");
  const [variantCost, setVariantCost] = useState(0);
  const [perBox, setPerBox] = useState(0);
  const [perRack, setPerRack] = useState(0);
  const [orderQTY, setOrderQTY] = useState(0);
  const [sheet, setSheet] = useState(0);
  const [soldByQty, setSoldByQty] = useState(1);
  const [soldBy, setSoldBy] = useState("PC");
  const [breakIntoPieces, setBreakIntoPieces] = useState(false);
  const [pieceMinQty, setPieceMinQty] = useState(1);
  const [breakIntoPiecesSm, setBreakIntoPiecesSm] = useState(true);
  const [isHidden, setIsHidden] = useState(false);

  const cleanProductVariantForm = () => {
    setVariantImage();
    setVariantImageUrl();
    variantImageRef.current.value = null;
    setVariantName("");
    setVariantSKU("");
    setVariantBarcode("");
    setVariantCode("");
    setVariantCost("");
    setPerBox(0);
    setPerRack(0);
    setOrderQTY(0);
    setSheet(0);
    setSoldByQty(0);
    setPieceMinQty(1);
    setBreakIntoPieces(false);
    setBreakIntoPiecesSm(true);
  };

  async function handleAddVariant(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    for (const pair of data.entries()) {
    }
    const skuAvailable = await checkSku(data.get("variantSKU"));
    if (!skuAvailable) {
      return;
    }
    const variant = {
      name: data.get("variantName"),
      sku: data.get("variantSKU"),
      cost: data.get("variantCost"),
      images: [{ image: variantImageUrl }],
      barcode: data.get("variantBarCode"),
      sold_by: {
        value: data.get("variantSoldBy"),
        label: soldByValMapper[data.get("variantSoldBy")],
      },
      //count: data.get('variantSKU'),
      //variantId: data.get('variantSKU'),
      per_box: data.get("perBox"),
      per_pack: data.get("perRack"),
      order_qty: data.get("orderQTY"),
      code: data.get("variantCode"),
      sold_by_qty: soldByQty || 1,
      break_into_pieces: breakIntoPieces,
      piece_min_qty: pieceMinQty,
      break_into_pieces_sp: breakIntoPiecesSm,
      //weight: data.get('variantSKU'),
      //weight_2: data.get('variantSKU'),
      sheet: data.get("sheet"),
      image: [data.get("variantImage")],
      overrides_count: 0,
    };
    addVariant(variant);
    cleanProductVariantForm();
  }

  const productVariantFormRef = useRef();

  function updateVariant(key) {
    const newVariants = { ...variants };
    if (newVariants[key]) {
      setVariantName(newVariants[key].name);
      setVariantSKU(newVariants[key].sku);
      setVariantBarcode(newVariants[key].barcode);
      setVariantCode(newVariants[key].code);
      setVariantCost(newVariants[key].cost);
      setPerBox(newVariants[key].per_box);
      setPerRack(newVariants[key].per_pack);
      setOrderQTY(newVariants[key].order_qty);
      setSheet(newVariants[key].sheet);
      setSoldByQty(newVariants[key].sold_by_qty);
      setPieceMinQty(newVariants[key].piece_min_qty);
      setBreakIntoPieces(newVariants[key].break_into_pieces);
      setBreakIntoPiecesSm(newVariants[key].break_into_pieces_sp);
      if (newVariants[key].images && newVariants[key].images.length > 0) {
        if (newVariants[key].images[0].image) {
          setVariantImageUrl(newVariants[key].images[0].image);
        }
      }
      if (newVariants[key].image && newVariants[key].image.length > 0) {
        if (
          newVariants[key].image[0] &&
          newVariants[key].image[0].size &&
          newVariants[key].image[0].size > 0
        ) {
          setVariantImage(newVariants[key].image[0]);
        }
      }
      delete newVariants[key];
    }
    setVariants(newVariants);
  }

  const handleCreateTag = async () => {
    const res = await dispatch(createTag(tagSearch));
    const data = res.payload;
    setSelectedTag((prev) => [...prev, { value: data.id, label: data.name }]);
    setTagSearch("");
  };

  const [isOpenDiscard, setIsOpenDiscard] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const handleDiscard = () => {
    fetchProduct();
    cleanProductOverrideForm();
    cleanProductVariantForm();
  };

  const handleDelete = () => {
    productsApi
      .delete(id)
      .then(() => {
        fireSuccessNotification(`Product deleted successfully`);
        navigate(-1);
      })
      .catch(() => {
        fireErrorNotification("Protected Error, you can't delete this product");
      });
  };

  const handleVariantImageChange = (e) => {
    if (isFileValid(e.target.files[0])) {
      setVariantImageUrl(URL.createObjectURL(e.target.files[0]));
      setVariantImage(e.target.files[0]);
    }
  };

  return (
    <Box margin=" 30px auto 0">
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "flex-start", md: "center" }}
        justifyContent="space-between"
        spacing="20px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ width: { xs: "100%", sm: "initial" }, gap: "20px" }}
        >
          <Button
            className="btn-height"
            onClick={() => navigate("/products")}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Typography variant="pageTitle" component="p">
            {name}
          </Typography>
        </Stack>
        <Stack
          direction={{ sm: "row-reverse", md: "row" }}
          alignItems="center"
          justifyContent="flex-end"
          flexWrap="wrap"
          sx={{
            width: {
              xs: "100%",
              sm: "initial",
            },
            gap: "11px",
            "@media(max-width: 600px)": {
              flexDirection: "row-reverse",
              width: "100%",
            },
            "@media(max-width: 480px)": {
              flexDirection: "column-reverse",
              width: "100%",
              button: { width: "100%" },
            },
          }}
        >
          <ConfirmDialog
            title="Discard updates?"
            open={isOpenDiscard}
            setOpen={setIsOpenDiscard}
            onConfirm={handleDiscard}
          >
            {`Are you sure you want to discard all changes?`}
          </ConfirmDialog>
          <ConfirmDialog
            title="Delete user?"
            open={isOpenDelete}
            setOpen={setIsOpenDelete}
            onConfirm={handleDelete}
          >
            {`Are you sure you want to delete this product?`}
          </ConfirmDialog>
          <Button
            className="btn-height"
            variant="contained"
            color="error"
            onClick={() => setIsOpenDelete(true)}
          >
            Delete
          </Button>
          <Button
            className="btn-height"
            variant="outlined"
            onClick={() => setIsOpenDiscard(true)}
          >
            Discard
          </Button>
          <Button
            className="btn-height"
            color="black"
            variant="contained"
            onClick={handleSubmit}
          >
            Save & continue Editing
          </Button>
        </Stack>
      </Stack>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 3, xl: "24px" }}
        margin="20px 0 0"
      >
        <Grid item md={7} lg={8} sx={{ maxWidth: "908px", width: "100%" }}>
          {Object.keys(errors).map((key) => (
            <Alert sx={{ mb: 1 }} severity="error">
              {errors[key]}
            </Alert>
          ))}
          <Block isLoading={isProductLoading}>
            <Typography component="p" variant="label" mb="11px">
              Name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter product's name"
              data-testid="test-name-id"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              required
            />
            <Typography component="p" variant="label" mb="11px" mt="19px">
              Description
            </Typography>
            <TinymceEditor
              value={description}
              onEditorChange={(e) => {
                setDescription(e);
              }}
            />
            <Typography component="p" variant="label" mb="11px" mt="19px">
              Additional info
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter additional info"
              data-testid="test-additional-id"
              value={additionalInfo}
              onChange={(e) => {
                setAdditionalInfo(e.target.value);
              }}
            />
          </Block>
          <Block isLoading={isProductLoading}>
            <Typography component="p" variant="label" mb="11px">
              Media
            </Typography>
            <FileDropzone setImages={setImages} />
            <ImageList cols={6} rowHeight={120} gap={15}>
              {[...currImages, ...images].map((item, index) => (
                <ProductImageListItem
                  key={index}
                  productId={id}
                  item={item}
                  setImages={setImages}
                  fetchItem={fetchProduct}
                  type="product"
                />
              ))}
            </ImageList>
          </Block>
          <Block>
            {Object.keys(variantsErrors).map((key) => (
              <Alert sx={{ mb: 1 }} severity="error">
                {variantsErrors[key]}
              </Alert>
            ))}
            <form id="tagCreation" onSubmit={handleAddVariant}>
              <Typography component="p" variant="label" mb="11px">
                Product Variants
              </Typography>
              <Stack
                direction="row"
                alignItems="flex-end"
                flexWrap="wrap"
                gap="17px"
                mb="20px"
              >
                <Box maxWidth="300px">
                  <Typography variant="inputLabel" component="p">
                    Option Name*
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    data-testid="test-option-name-id"
                    name="variantName"
                    required
                    id="variantName"
                    value={variantName}
                    onChange={(e) => setVariantName(e.target.value)}
                  />
                </Box>
                <Box maxWidth="140px">
                  <Typography variant="inputLabel" component="p">
                    SKU*
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    data-testid="test-sku-id"
                    name="variantSKU"
                    required
                    onChange={(e) => {
                      setVariantSKU(e.target.value);
                    }}
                    value={variantSKU}
                  />
                </Box>
                <Box maxWidth="170px">
                  <Typography variant="inputLabel" component="p">
                    Barcode
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="variantBarCode"
                    data-testid="test-variant-bar-code-id"
                    value={variantBarCode}
                    onChange={(e) => setVariantBarcode(e.target.value)}
                  />
                </Box>
                <Box maxWidth="150px">
                  <Typography variant="inputLabel" component="p">
                    Code
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="variantCode"
                    data-testid="test-variant-code-id"
                    value={variantCode}
                    onChange={(e) => setVariantCode(e.target.value)}
                  />
                </Box>
              </Stack>
              <Stack
                direction="row"
                alignItems="flex-end"
                flexWrap="wrap"
                gap="17px"
                mb="20px"
              >
                <Box maxWidth="180px" minWidth="180px">
                  <Typography variant="inputLabel" component="p">
                    Sold by*
                  </Typography>
                  <Select
                    fullWidth
                    required
                    name="variantSoldBy"
                    onChange={(e) => setSoldBy(e.target.value)}
                    value={soldBy}
                    sx={{
                      "& .MuiSelect-select": {
                        padding: "8.5px 32px 8.5px 12px",
                      },
                    }}
                  >
                    {Object.keys(SOLD_BY_OPTIONS).map((key) => (
                      <MenuItem value={key}>
                        {SOLD_BY_OPTIONS[key].name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box maxWidth="120px">
                  <Typography variant="inputLabel" component="p">
                    {SOLD_BY_OPTIONS[soldBy].name} cost*
                  </Typography>
                  <OutlinedInput
                    fullWidth
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    name="variantCost"
                    inputProps={{ step: "0.1", lang: "en-US" }}
                    value={variantCost}
                    type="number"
                    onChange={(e) => setVariantCost(e.target.value)}
                    required
                  />
                </Box>
                {SOLD_BY_OPTIONS[soldBy]?.multiple && (
                  <>
                    <Box maxWidth="120px">
                      <Typography variant="inputLabel" component="p">
                        items per {SOLD_BY_OPTIONS[soldBy].name}
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="variantSoldByQty"
                        data-testid="test-variant-sold-by-qty-id"
                        required
                        type="number"
                        inputProps={{ step: "1", lang: "en-US" }}
                        value={soldByQty}
                        onChange={(e) => setSoldByQty(e.target.value)}
                      />
                    </Box>
                    <Box maxWidth="180px">
                      <Typography variant="inputLabel" component="p">
                        Broken to pieces? (sales person)
                      </Typography>
                      <Switch
                        checked={breakIntoPiecesSm}
                        onChange={(event) =>
                          setBreakIntoPiecesSm(event.target.checked)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Box>
                    <Box maxWidth="180px">
                      <Typography variant="inputLabel" component="p">
                        Broken to pieces? (client)
                      </Typography>
                      <Switch
                        checked={breakIntoPieces}
                        onChange={(event) =>
                          setBreakIntoPieces(event.target.checked)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Box>
                    {breakIntoPieces && (
                      <Stack
                        direction="row"
                        alignItems="flex-end"
                        flexWrap="wrap"
                        gap="17px"
                        mb="20px"
                      >
                        <Box maxWidth="120px">
                          <Typography variant="inputLabel" component="p">
                            Cost per piece
                          </Typography>
                          <OutlinedInput
                            fullWidth
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            value={(
                              Number(variantCost / soldByQty) || 0
                            ).toFixed(2)}
                            disabled
                          />
                        </Box>
                        <Box maxWidth="120px">
                          <Typography variant="inputLabel" component="p">
                            minimum qty
                          </Typography>
                          <TextField
                            fullWidth
                            variant="outlined"
                            data-testid="test-minimum-qty-id"
                            name="minQty"
                            required
                            type="number"
                            value={pieceMinQty}
                            onChange={(e) => setPieceMinQty(e.target.value)}
                          />
                        </Box>
                      </Stack>
                    )}
                  </>
                )}
              </Stack>
              <Stack
                direction="row"
                alignItems="flex-end"
                flexWrap="wrap"
                gap="17px"
                mb="20px"
              >
                <Typography>Optional Info</Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="flex-end"
                flexWrap="wrap"
                gap="17px"
                mb="20px"
              >
                <Box maxWidth="95px">
                  <Typography variant="inputLabel" component="p">
                    Per Box
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    data-testid="test-per-box-id"
                    type="number"
                    name="perBox"
                    value={perBox}
                    onChange={(e) => setPerBox(e.target.value)}
                  />
                </Box>
                <Box maxWidth="95px">
                  <Typography variant="inputLabel" component="p">
                    Per Rack
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    data-testid="test-per-pack-id"
                    type="number"
                    name="perRack"
                    value={perRack}
                    onChange={(e) => setPerRack(e.target.value)}
                  />
                </Box>
                <Box maxWidth="95px">
                  <Typography variant="inputLabel" component="p">
                    Order QTY
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    data-testid="test-order-qty-id"
                    type="number"
                    name="orderQTY"
                    value={orderQTY}
                    onChange={(e) => setOrderQTY(e.target.value)}
                  />
                </Box>
                <Box maxWidth="95px">
                  <Typography variant="inputLabel" component="p">
                    Sheet
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    data-testid="test-sheet-id"
                    type="number"
                    name="sheet"
                    value={sheet}
                    onChange={(e) => setSheet(e.target.value)}
                  />
                </Box>
                <Box maxWidth="140px">
                  <Stack direction="row">
                    <Box>
                      <input
                        style={{ display: "none" }}
                        accept="image/*"
                        id={`icon`}
                        type="file"
                        name="variantImage"
                        ref={variantImageRef}
                        onChange={handleVariantImageChange}
                      />
                      <label htmlFor={`icon`}>
                        <Button
                          fullWidth
                          component="span"
                          //variant="contained"
                          sx={{ pt: 0 }}
                          //startIcon={<FileOpenIcon />}
                        >
                          {!variantImage && <img src={ImgPlaceholder} alt="" />}
                          {variantImage && (
                            <img src={`${variantImageUrl}`} loading="lazy" />
                          )}
                        </Button>
                      </label>
                    </Box>
                    {variantImage && (
                      <Box>
                        <IconButton
                          sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          onClick={() => {
                            setVariantImage(null);
                            setVariantImageUrl(null);
                            variantImageRef.current.value = null;
                          }}
                        >
                          <DeleteForeverOutlinedIcon fontSize={"small"} />
                        </IconButton>
                      </Box>
                    )}
                  </Stack>
                </Box>
              </Stack>
              <Stack direction="row" flexWrap="wrap" gap={2}>
                <Button color="primary" variant="contained" type="submit">
                  Create Option
                </Button>
              </Stack>
            </form>
          </Block>

          <Block isLoading={isProductLoading}>
            <Typography component="p" variant="label" mb="11px">
              Variant Config
            </Typography>
            <Box sx={{ margin: { xs: "0 -16px -16px", sm: "0 -24px -24px" } }}>
              <VariantConfigTable
                vars={variants}
                setVariants={setVariants}
                pid={id}
                updateVariant={updateVariant}
              />
            </Box>
          </Block>

          <Block isLoading={productOrdersHistoryLoading}>
            <Typography component="p" variant="label" mb="11px">
              Orders History
            </Typography>
            <Box sx={{ margin: { xs: "0 -16px -16px", sm: "0 -24px -24px" } }}>
              <ProductOrdersHistory type="product" id={id} />
            </Box>
          </Block>
        </Grid>

        <Grid item md={5} lg={4} sx={{ width: "100%" }}>
          <Block isLoading={isProductLoading}>
            <Typography component="p" variant="label" mb="11px">
              Category
            </Typography>
            <CategorySelect
              label="Product Category"
              name="category"
              value={selectedCategory}
              onChange={(_e, newValue) => setSelectedCategory(newValue)}
            />
            <Separator />
            <Typography component="p" variant="label" mb="11px">
              Vendor
            </Typography>
            <VendorSelect
              name="vendor"
              label="Product Vendor"
              value={selectedVendor}
              fullWidth
              onChange={(_event, newValue) => {
                setSelectedVendor(null);
                if (newValue) {
                  if (newValue.value) {
                    setSelectedVendor(newValue);
                    //setVendor(newValue.value)
                  }
                }
              }}
            />
          </Block>
          <Block isLoading={isProductLoading}>
            <Typography component="p" variant="label" mb="11px">
              Hidden
            </Typography>
            <Select
              fullWidth
              value={isHidden}
              onChange={(e) => setIsHidden(e.target.value)}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </Block>
          <Block isLoading={isProductLoading}>
            <Stack direction="row" justifyContent="space-between" mb="20px">
              <Typography component="p" variant="label">
                Product Tags
              </Typography>
              <Link to="/tags" component={NavLink}>
                Manage
              </Link>
            </Stack>
            <TagSelect
              multiple
              label="Product Tags"
              id="multiple-limit-tags"
              value={selectedTag}
              onChange={(_event, newValue) => {
                setSelectedTag(newValue);
              }}
              setTagSearch={setTagSearch}
              noOptionsText={
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>There is no such variant</Typography>
                  <Button onClick={handleCreateTag}>Create</Button>
                </Stack>
              }
            />
          </Block>

          <Block isLoading={isProductLoading}>
            <Typography component="p" variant="label" mb="11px">
              Product Status
            </Typography>
            <Select
              fullWidth
              value={isActive}
              onChange={(e) => setIsActive(e.target.value)}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
            <Separator />
            <Typography component="p" variant="label" mb="11px">
              Delivery Type
            </Typography>
            <Select
              fullWidth
              value={deliveryType}
              onChange={(e) => setDeliveryType(e.target.value)}
            >
              <MenuItem value="MORNING">Morning</MenuItem>
              <MenuItem value="SALES_MAN">Salesman</MenuItem>
            </Select>
            {/*
            <Separator />
            <Typography component="p" variant="label" mb="11px">
              Availability
            </Typography>
            <Select fullWidth sx={{ marginBottom: "12px" }}>
              <MenuItem value="always">Always</MenuItem>
              <MenuItem value="seasonal">Seasonal</MenuItem>
            </Select>
            <Select fullWidth sx={{ marginBottom: "12px" }}>
              <MenuItem value="all">All days</MenuItem>
              <MenuItem value="specific">Specific Weekdays</MenuItem>
            </Select>
            <Select
              fullWidth
              multiple
              value={personName}
              onChange={multipleSelectChange}
              sx={{ marginBottom: "15px" }}
            >
              <MenuItem value="purim">Purim</MenuItem>
              <MenuItem value="purim2">Purim2</MenuItem>
            </Select>
            <Button color="primary" variant="contained">
              Add Season
            </Button>
            */}
          </Block>

          <Block isLoading={isProductOverridesLoading}>
            <Typography component="p" variant="label" mb="11px">
              Price Settings
            </Typography>
            <Typography component="p" fontWeight="bold" mb="5px">
              Upcharge Percentage
            </Typography>
            <Stack direction="row" alignItems="center" gap="10px">
              <Typography fontWeight="bold" sx={{ color: "#313131" }}>
                {Number(upcharges.category_upcharge.value) +
                  Number(upcharges.global_upcharge) +
                  Number(upcharges.vendor_upcharge.value)}
                %
              </Typography>

              <HtmlTooltip
                title={
                  <div>
                    <Typography color="inherit">
                      Category upcharge:{" "}
                      {(+upcharges.category_upcharge.value).toFixed(2)}%
                    </Typography>
                    <Typography color="inherit">
                      Vendor upcharge:{" "}
                      {(+upcharges.vendor_upcharge.value).toFixed(2)}%
                    </Typography>
                    <Typography color="inherit">
                      Global upcharge: {(+upcharges.global_upcharge).toFixed(2)}
                      %
                    </Typography>
                  </div>
                }
              >
                <Link sx={{ fontSize: "13px", color: "#000" }}>
                  View Breakdown
                </Link>
              </HtmlTooltip>
            </Stack>
            <Separator />
            {/*
            <Typography component="p" fontWeight={600} mb="12px">
              Scheduled upcharge Changes
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>June 2 ‘23</TableCell>
                  <TableCell sx={{ flex: 1 }}>13%</TableCell>
                  <TableCell>
                    <IconButton>
                      <img src={EyeIcon} alt="" />
                    </IconButton>
                    <IconButton>
                      <EditOutlinedIcon fontSize={"small"} />
                    </IconButton>
                    <IconButton>
                      <DeleteForeverOutlinedIcon fontSize={"small"} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Separator />
            */}
            {Object.keys(overrideError).map((key) => (
              <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                {key}: {overrideError[key]}
              </Alert>
            ))}
            {overrideSuccess && (
              <Alert severity="success" sx={{ mt: 2, mb: 2 }}>
                {overrideSuccess}
              </Alert>
            )}
            <Typography component="p" fontWeight={600} mb="12px">
              Advanced Override
            </Typography>
            <Typography component="p" mb="12px">
              Override type
            </Typography>
            <form id="overrideCreation" onSubmit={submitOverride}>
              <RadioGroup
                row
                name="override-type"
                defaultValue="account"
                sx={{ my: "10px" }}
              >
                <FormControlLabel
                  value="territory"
                  control={<Radio />}
                  label="Territory"
                  checked={overrideType == "territory"}
                  onChange={() => setOverrideType("territory")}
                />
                <FormControlLabel
                  value="account"
                  control={<Radio />}
                  label="Account"
                  checked={overrideType == "account"}
                  onChange={() => setOverrideType("account")}
                />
                <FormControlLabel
                  value="customer"
                  control={<Radio />}
                  label="Customer"
                  checked={overrideType == "customer"}
                  onChange={() => setOverrideType("customer")}
                />
              </RadioGroup>
              <Grid container spacing={2} sx={{ marginBottom: "5px" }}>
                <Grid item xs={8}>
                  {overrideType == "account" && (
                    <AccountSelect
                      name="account"
                      label="Account"
                      required
                      value={selectedAccountOverride}
                      onChange={(_event, newValue) => {
                        setSelectedAccountOverride(null);
                        if (newValue) {
                          if (newValue.value) {
                            setSelectedAccountOverride(newValue);
                          }
                        }
                      }}
                    />
                  )}
                  {overrideType == "customer" && (
                    <CustomerSelect
                      name="customer"
                      label="Customer"
                      required
                      value={selectedCustomerOverride}
                      onChange={(_event, newValue) => {
                        setSelectedCustomerOverride(null);
                        if (newValue) {
                          if (newValue.value) {
                            setSelectedCustomerOverride(newValue);
                          }
                        }
                      }}
                    />
                  )}
                  {overrideType == "territory" && (
                    <TerritorySelect
                      name="customer"
                      label="Territory"
                      required
                      value={selectedTerritoryOverride}
                      onChange={(_event, newValue) => {
                        setSelectedTerritoryOverride(null);
                        if (newValue) {
                          if (newValue.value) {
                            setSelectedTerritoryOverride(newValue);
                          }
                        }
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="outlined">
                    <OutlinedInput
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      size="small"
                      name="override"
                      type="number"
                      sx={{
                        "& .MuiInputBase-input.MuiOutlinedInput-input": {
                          padding: "9px 12px 8px",
                        },
                      }}
                      onChange={(e) => setOverride(e.target.value)}
                      value={override}
                      required
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    size="small"
                    controlledValue={overrideStartDate}
                    setControlledValue={setOverrideStartDate}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    size="small"
                    controlledValue={overrideEndDate}
                    setControlledValue={setOverrideEndDate}
                  />
                </Grid>
              </Grid>
              <Stack direction="row" flexWrap="wrap" gap="10px" mt="15px">
                <Button
                  variant="contained"
                  type="submit "
                  disabled={overrideSubmitting}
                >
                  Save Override
                </Button>
              </Stack>
            </form>
            {Object.keys(currentTerritoriesOverrides).length > 0 && (
              <div>
                <Separator />
                <Typography component="p" fontWeight={600} mb="12px">
                  Territory Overrides
                </Typography>
                <Box sx={{ width: "100%" }}>
                  {Object.keys(currentTerritoriesOverrides).map((key) => (
                    <ProductOverrideAccordion
                      overrides={currentTerritoriesOverrides}
                      elementId={key}
                      elementKey={"territory"}
                      productId={id}
                      deleteOverride={deleteProductOverride}
                      updateOverride={updateProductOverride}
                      updateOverridesTable={fetchTerritoriesOverrides}
                    />
                  ))}
                </Box>
              </div>
            )}
            {Object.keys(currentCustomersOverrides).length > 0 && (
              <div>
                <Separator />
                <Typography component="p" fontWeight={600} mb="12px">
                  Customer Overrides
                </Typography>
                <Box sx={{ width: "100%", overflow: "auto" }}>
                  {Object.keys(currentCustomersOverrides).map((key) => (
                    <ProductOverrideAccordion
                      overrides={currentCustomersOverrides}
                      elementId={key}
                      elementKey={"customer"}
                      productId={id}
                      deleteOverride={deleteProductOverride}
                      updateOverride={updateProductOverride}
                      updateOverridesTable={fetchCustomerOverrides}
                    />
                  ))}
                </Box>
              </div>
            )}
            {Object.keys(currentAccountsOverride).length > 0 && (
              <div>
                <Separator />
                <Typography component="p" fontWeight={600} mb="12px">
                  Account Overrides
                </Typography>
                <Box sx={{ width: "100%", overflow: "auto" }}>
                  {Object.keys(currentAccountsOverride).map((key) => (
                    <ProductOverrideAccordion
                      overrides={currentAccountsOverride}
                      elementId={key}
                      elementKey={"account"}
                      productId={id}
                      deleteOverride={deleteProductOverride}
                      updateOverride={updateProductOverride}
                      updateOverridesTable={fetchAccountOverrides}
                    />
                  ))}
                </Box>
              </div>
            )}
          </Block>
          {/*
          <Block>
            <Typography component="p" variant="label" mb="12px">
              Selling Restrictions
            </Typography>
            <Typography component="p" mb="12px">
              Restriction type
            </Typography>
            <RadioGroup
              row
              name="restriction-type"
              defaultValue="account"
              sx={{ my: "15px" }}
            >
              <FormControlLabel
                value="territory"
                control={<Radio />}
                label="Territory"
              />
              <FormControlLabel
                value="account"
                control={<Radio />}
                label="Account"
              />
              <FormControlLabel
                value="customer"
                control={<Radio />}
                label="Customer"
              />
              <FormControlLabel
                value="preorder"
                control={<Radio />}
                label="Preorder"
              />
              <FormControlLabel
                value="approval-required"
                control={<Radio />}
                label="Approval Required"
              />
            </RadioGroup>
            <Stack
              direction="row"
              alignItems="center"
              flexWrap="wrap"
              gap="13px"
            >
              <Typography
                noWrap
                fontSize="15px"
                sx={{ color: "#313131", flexShrink: 0 }}
              >
                Preorder Rule
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  border: "1px solid #DBDFEA",
                  borderRadius: "5px",
                  backgroundColor: "#E5E9F2",
                  textAlign: "center",
                }}
              >
                <Typography
                  fontSize="13px"
                  sx={{ color: "#7E7E7E", width: "56px" }}
                >
                  Days
                </Typography>
                <TextField
                  inputProps={{
                    type: "number",
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  sx={{
                    width: "59px",
                    "& input": { textAlign: "center" },
                    "& .MuiOutlinedInput-root": { borderRadius: 0 },
                  }}
                />
                <Typography
                  fontSize="13px"
                  sx={{ color: "#7E7E7E", width: "56px" }}
                >
                  Time
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    height: "44px",
                    display: "flex",
                    borderRadius: "0 5px 5px 0",
                    overflow: "hidden",
                  }}
                >
                  <input type="time" className="time-input" />
                </Box>
              </Stack>
            </Stack>
            <Stack direction="row" flexWrap="wrap" gap="10px" mt="15px">
              <Button variant="gray" sx={{ padding: "6px 10px" }}>
                Add Additional Restrictions
              </Button>
              <Button variant="contained">Save Restrictions</Button>
            </Stack>

            <Separator />

            <Typography component="p" fontWeight={600} mb="12px">
              Restrictions
            </Typography>
            <Table>
              <TableBody>
                {[
                  "Evergreen Monsey",
                  "Flatbush",
                  "Requires 2 day prior notice by 4pm",
                  "Approval Required",
                ].map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>{row}</TableCell>
                    <TableCell>
                      <IconButton>
                        <img src={EyeIcon} alt="eye" />
                      </IconButton>
                      <IconButton>
                        <img src={EditIcon} alt="edit" />
                      </IconButton>
                      <IconButton>
                        <img src={TrashIcon} alt="trash" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Block>
*/}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateProduct;
