import { fetchAccountVariantOverrides } from "../../../distflowAPI/accountsApi";
import { useState, useEffect } from "react";
import { IconButton, Tooltip, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useModal } from "mui-modal-provider";
import {
  CheckCircleOutlineRounded,
  HighlightOff,
  VisibilityRounded,
} from "@mui/icons-material";
import dayjs from "dayjs";
import DataTable from "../../../components/DataTable";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { DATE_FORMAT_FRONT } from "../../../utils/constants";
import { ChangesModal } from "../../../components/History";
import { getNoPages } from "../../../utils";

const infoFontSize = "14px";
const defaultCellStyle = { padding: " 5px 0 5px 18px" };
const defaultColDef = { flex: 1, minWidth: 100 };
export default function VariantOverridesTable({ accountId }) {
  const [overrides, setOverrides] = useState([]);
  const [noPages, setNoPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const { showModal } = useModal();
  function loadData() {
    fetchAccountVariantOverrides(accountId, page, pageSize).then((res) => {
      setOverrides(res.data.results);
      setNoPages(getNoPages(res.data.count, pageSize));
    });
  }
  useEffect(() => {
    loadData();
  }, [accountId, page, pageSize]);
  function handleRemove() {}
  const DateRenderer = (params) => {
    return (
      <Typography sx={{ color: "#1D1C1C", fontSize: infoFontSize }}>
        {params.value ? dayjs.utc(params.value).format(DATE_FORMAT_FRONT) : "-"}
      </Typography>
    );
  };
  const OverrideRenderer = (params) => {
    let fixedValue = parseFloat(params.value);
    if (fixedValue) {
      fixedValue = fixedValue.toFixed(4);
    }
    return (
      <Typography sx={{ color: "#1D1C1C", fontSize: infoFontSize }}>
        {fixedValue || params.value} %
      </Typography>
    );
  };
  const OverrideActiveRenderer = (params) => {
    return (
      <Typography
        sx={{
          color: "#1D1C1C",
          fontSize: infoFontSize,
          margin: "0 auto 0 10px",
        }}
      >
        {params.data.is_current ? (
          <CheckCircleOutlineRounded color="success" />
        ) : (
          <HighlightOff color="error" />
        )}
      </Typography>
    );
  };
  const ActionRenderer = (params) => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const historyData = params?.data?.history || [];

    const handleDelete = () => {
      //handleRemove(params.data[elementKey], params.data.id);
    };
    return <></>;
    return (
      <>
        <IconButton onClick={() => {}}>
          <EditOutlinedIcon />
        </IconButton>
        <Tooltip
          title={`${historyData.length} changes`}
          onClick={() =>
            showModal(ChangesModal, {
              historyData: historyData,
            })
          }
        >
          <IconButton>
            <VisibilityRounded />
          </IconButton>
        </Tooltip>
        <IconButton
          onClick={() => {
            setConfirmOpen(true);
          }}
        >
          <DeleteForeverOutlinedIcon />
        </IconButton>
        <ConfirmDialog
          title="Delete upcharge?"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleDelete}
        >
          {`Are you sure you want to remove this ${params.data.override}% override 
          from ${params.data.start_date}${params.data.end_date ? ` to ${params.data.end_date}` : ""}?`}
        </ConfirmDialog>
      </>
    );
  };
  const columnDefs = [
    {
      headerName: "Status",
      field: "is_current",
      cellRenderer: OverrideActiveRenderer,
      cellStyle: defaultCellStyle,
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Override",
      field: "override",
      cellRenderer: OverrideRenderer,
      cellStyle: defaultCellStyle,
      flex: 2,
      minWidth: 80,
    },
    {
      headerName: "Variant",
      valueGetter: (params) => params?.data?.variant_data?.name,
      cellStyle: defaultCellStyle,
      flex: 2,
      minWidth: 100,
    },
    {
      headerName: "SKU",
      valueGetter: (params) => params?.data?.variant_data?.sku,
      cellStyle: defaultCellStyle,
      flex: 2,
      minWidth: 80,
    },
    {
      headerName: "Price",
      valueGetter: (params) => "$ " + params?.data?.price,
      cellStyle: defaultCellStyle,
      flex: 2,
      minWidth: 80,
    },
    {
      headerName: "Start date",
      field: "start_date",
      cellRenderer: DateRenderer,
      cellStyle: defaultCellStyle,
      flex: 2,
      minWidth: 140,
    },
    {
      headerName: "End date",
      field: "end_date",
      cellRenderer: DateRenderer,
      cellStyle: defaultCellStyle,
      flex: 2,
      minWidth: 140,
    },
    {
      headerName: "",
      field: "actions",
      cellRenderer: ActionRenderer,
      cellStyle: defaultCellStyle,
      flex: 2,
      minWidth: 120,
    },
  ];
  return (
    <div className="variant-table">
      <DataTable
        rowData={overrides}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowHeight={73}
        page={page}
        pageSize={pageSize}
        noPages={noPages}
        setPageSize={setPageSize}
        setPage={setPage}
      />
    </div>
  );
}
