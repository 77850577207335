import { Grid, IconButton, Button } from "@mui/material";
import Block from "../../components/Block";
import DataTable from "../../components/DataTable";
import { useState, useEffect } from "react";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ConfirmDialog from "../../components/ConfirmDialog";
import {
  getCategoryAccountRestrictions,
  deleteCategoryAccountRestrictions,
  getVendorAccountRestrictions,
  deleteVendorAccountRestriction,
} from "../../distflowAPI/accountsApi";
import { useParams } from "react-router-dom";

import CreateVendorRestriction from "./CreateVendorRestriction";
export default function RestrictVendorAccounts() {
  const { id } = useParams();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [rowData, setRowData] = useState();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [customerSelectOpen, setCustomerSelectOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  function fetchRestrictions() {
    getVendorAccountRestrictions(id, pageSize, page).then((res) => {
      setRowData(res.data.results);
      setCount(res.data.count);
    });
  }
  useEffect(() => {
    fetchRestrictions();
  }, []);

  function ButtonsRenderer({ item }) {
    const handleDeleteClick = () => {
      console.log("setting item to delete to: ", item);
      setItemToDelete(item);
    };

    return (
      <div>
        <IconButton
          onClick={() => {
            handleDeleteClick();
          }}
        >
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </div>
    );
  }
  const columns = [
    {
      headerName: "Vendor",
      field: "vendor.name",
      minWidth: 400,
      sortable: true,
      valueGetter: (params) => params.data?.vendor?.name,
    },
    {
      headerName: "Notes",
      field: "note",
      sortable: true,
    },
    {
      headerName: "Policy",
      field: "policy",
      sortable: true,
    },
    {
      headerName: "Actions",
      cellRenderer: (params) => <ButtonsRenderer item={params?.data} />,
      maxWidth: 100,
    },
  ];
  const defaultColDef = {
    flex: 1,
    filter: false,
    floatingFilter: false,
  };
  return (
    <Grid
      container
      columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
      margin="20px 0 0"
    >
      <Grid item md={9} lg={9} sx={{ maxWidth: "908px", width: "100%" }}>
        <Block>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3 style={{ fontWeight: 600 }}>Vendor Restrictions</h3>
            <Button
              type="submit"
              variant="contained"
              sx={{ pt: 1, pb: 1, mb: 1 }}
              onClick={() => {
                setCustomerSelectOpen(true);
              }}
            >
              Create a Restriction
            </Button>
            <CreateVendorRestriction
              customerSelectOpen={customerSelectOpen}
              setCustomerSelectOpen={setCustomerSelectOpen}
              fetchRestrictions={fetchRestrictions}
            />
            <ConfirmDialog
              title="Restrictions"
              children={"Do you want to delete this Restriction ?"}
              open={itemToDelete}
              setOpen={() => {
                setItemToDelete(null);
              }}
              onConfirm={() => {
                console.log("removing row data: ", itemToDelete);
                deleteVendorAccountRestriction(id, itemToDelete.id).then(
                  (res) => {
                    fetchRestrictions();
                  }
                );
              }}
            />
          </div>
          <DataTable
            rowData={rowData}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            loading={false}
          />
        </Block>
      </Grid>
    </Grid>
  );
}
