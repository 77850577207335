import { useState, useEffect } from "react";
import DataTable from "../../components/DataTable";
import commissionsCols from "./commissionsCols";
import { listComissions } from "../../distflowAPI/salesmenApi";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DatePickerCustom2 from "../../components/DatePicker2";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../utils/constants";
import { getNoPages } from "../../utils";
import { downloadFile } from "../../distflowAPI/downloadFile";
import { filtersToApi } from "../../distflowAPI/common";

export default function CommissionTable({ salesManId }) {
  const [comissions, setComissions] = useState([]);
  const [filterBy, setFilterBy] = useState({
    created_date__gte: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    created_date__lte: dayjs().format("YYYY-MM-DD"),
    paid: "",
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [grouped, setGrouped] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noPages, setNoPages] = useState(10);
  useEffect(() => {
    console.log("filter by is: ", filterBy);
    setIsLoading(true);
    const filterObj = {
      ...filterBy,
      grouped: grouped ? "true" : "false",
    };
    const filterList = Object.keys(filterObj).map((fk) => {
      return { column: fk, value: filterObj[fk] };
    });
    listComissions(salesManId, page, pageSize, filterList).then((res) => {
      setComissions(res.data.results);
      setIsLoading(false);
      setNoPages(getNoPages(res.data.count, pageSize));
    });
  }, [salesManId, page, pageSize, filterBy, grouped]);

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} spacing={2}>
        <Button onClick={() => setGrouped(!grouped)} variant={"outlined"}>
          {grouped ? "UnGroup" : "Group by account "}
        </Button>
        <CommissionsFilters filterBy={filterBy} setFilterBy={setFilterBy} />
        <Button
          onClick={() => {
            let filterByS = "";
            Object.keys(filterBy).map((key) => {
              filterByS += `${key}=${filterBy[key]}&`;
            });
            downloadFile(
              `/api/accounting/salesmen/${salesManId}/comissions/download/?${filterByS}`,
              `commissions_export.csv`
            );
          }}
          variant={"outlined"}
        >
          Export
        </Button>
      </Stack>
      <DataTable
        columnDefs={grouped ? groupedComissionsCols : commissionsCols}
        rowData={comissions}
        loading={isLoading}
        page={page}
        pageSize={pageSize}
        setPage={(p) => setPage(p)}
        setPageSize={(ps) => setPageSize(ps)}
        noPages={noPages}
      />
    </Stack>
  );
}

function CommissionsFilters({ filterBy, setFilterBy }) {
  const [minDate, setMinDate] = useState(dayjs().subtract(7, "days"));
  const [maxDate, setMaxDate] = useState(dayjs());
  function setFilterStatus(value) {
    const newFilter = { ...filterBy };
    newFilter["paid"] = value;
    setFilterBy(newFilter);
  }

  function setMinDateFilter(value) {
    const newFilter = { ...filterBy };
    newFilter["created_date__gte"] = minDate.format("YYYY-MM-DD");
    setFilterBy(newFilter);
  }
  function setMaxDateFilter(value) {
    const newFilter = { ...filterBy };
    newFilter["created_date__lte"] = maxDate.format("YYYY-MM-DD");
    setFilterBy(newFilter);
  }

  function setFilterSearch(value) {
    const newFilter = { ...filterBy };
    newFilter["search"] = value;
    setFilterBy(newFilter);
  }
  useEffect(() => {
    setMinDateFilter(minDate);
  }, [minDate]);
  useEffect(() => {
    setMaxDateFilter(maxDate);
  }, [maxDate]);
  return (
    <Stack direction={"row"} spacing={2}>
      <TextField
        label={"account search"}
        value={filterBy.search}
        onChange={(e) => setFilterSearch(e.target.value)}
      />
      <RadioGroup
        value={filterBy["paid"]}
        onChange={(e) => setFilterStatus(e.target.value)}
        row
      >
        <FormControlLabel control={<Radio />} label={"All"} value={""} />
        <FormControlLabel control={<Radio />} label={"Paid"} value={true} />
        <FormControlLabel control={<Radio />} label={"UnPaid"} value={false} />
      </RadioGroup>
      <DatePickerCustom2
        label={"min date"}
        controlledValue={minDate}
        setControlledValue={setMinDate}
        maxWidth={180}
      />
      <DatePickerCustom2
        label={"max date"}
        controlledValue={maxDate}
        setControlledValue={setMaxDate}
        maxWidth={180}
      />
    </Stack>
  );
}

const groupedComissionsCols = [
  {
    headerName: "Account",
    field: "account.name",
    minWidth: 300,
  },

  {
    headerName: "Total Invoices",
    field: "total_amount",
    cellRenderer: (params) => (
      <Typography>${params?.data?.total_amount}</Typography>
    ),
    minWidth: 120,
    maxWidth: 150,
  },
  {
    headerName: "Total Open Invoices",
    field: "total_amount",
    cellRenderer: (params) => (
      <Typography>${params?.data?.total_open_amount}</Typography>
    ),
    minWidth: 180,
    maxWidth: 180,
  },
  {
    headerName: "Total Commissions",
    field: "commissions_total",
    cellRenderer: (params) => (
      <Typography>${params?.data?.commissions_total}</Typography>
    ),
    maxWidth: 180,
    minWidth: 180,
  },
  {
    headerName: "Total Paid",
    field: "total_paid",
    cellRenderer: (params) => (
      <Typography>${params?.data?.total_paid}</Typography>
    ),
    maxWidth: 140,
  },
  {
    headerName: "Total Unpaid",
    valueGetter: (params) =>
      params?.data?.commissions_total - params?.data?.total_paid,
    cellRenderer: (params) => (
      <Typography>
        $
        {(params?.data?.commissions_total - params?.data?.total_paid).toFixed(
          2
        )}
      </Typography>
    ),
    maxWidth: 140,
  },
  {
    headerName: "Invoices Count",
    field: "invoices_count",
    maxWidth: 140,
  },
  {
    headerName: "Open Invoices Count",
    field: "open_invoices_count",
  },
];
