import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import DatePickerCustom2 from "../../../components/DatePicker2";
import dayjs from "dayjs";
import { useState } from "react";
import { generateSteadiesExport } from "../../../distflowAPI/ordersApi";
import { DATE_FORMAT } from "../../../utils/constants";
import { downloadFile } from "../../../distflowAPI/downloadFile";
function GenerateSteadiesEmail({ open, setOpen }) {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDtate, setEndDate] = useState(dayjs());
  const [emailingMismatch, setEMailingMismatch] = useState(false);
  function emailMismatchReport() {
    setEMailingMismatch(true);
    downloadFile(`/api/orders/export_future_orders/`, "steadies.csv").finally(
      (res) => {
        setEMailingMismatch(false);
        setOpen(false);
      }
    );
  }
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Typography>Email Steadies</Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}></DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <Button
            disabled={emailingMismatch}
            variant="contained"
            color="error"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
          <Button
            disabled={emailingMismatch}
            onClick={emailMismatchReport}
            variant="contained"
            color="success"
          >
            Generate
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default GenerateSteadiesEmail;
