import {
  Box,
  Button,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import DataTable from "../../components/DataTable";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as OpenIc } from "../../images/open-ic.svg";
import dayjs from "dayjs";
import { useModal } from "mui-modal-provider";
import HtmlTooltip from "../../components/HtmlToolTip";
import OrderItemsModal from "../../components/OrderComponents/OrderItemsModal";
import { DATE_FORMAT_FRONT } from "../../utils/constants";
import { fulfillmentStatus, paymentStatus } from "./consts";
import ErrorPopupBoundary from "../../components/ErrorPopupBoundary";
import React, { useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import RouteRenderer from "../../components/OrderComponents/orderTableCols/orderRoutesCol";
import { Dialog } from "@mui/material";
import OrderItemsModalCopy from "../../components/OrderComponents/CopyOrder/OrderItemsModalCopy";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Flag } from "@mui/icons-material";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);
const defaultCellStyle = {
  fontSize: "14px",
  lineHeight: "22px",
  paddingLeft: "15px",
  paddingRight: "15px",
};

const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const OrdersTable = ({ items, refreshTable }) => {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const [orderToCopy, setOrderToCopy] = useState(false);
  const OrdersRenderer = (params) => {
    const manageOrderClick = () => {
      const date = params.data.date;
      const accountId = params.data.accountId;
      const day = dayjs.utc(date).format("ddd").toLowerCase();
      navigate(
        `/orders/manage-auto-orders?date=${date}&accountId=${accountId}&day=${day}`
      );
    };
    function getOrderId() {
      console.log("order is: ", params.data);
      if (params.data?.po_id) {
        return `PO# ${params.data.po_id}`;
      }
      if (params.data.invoice?.id) {
        return `INV#${params.data.invoice?.id}`;
      }
      if (params.data.auId) {
        return `AU#${params.data.auId}`;
      }
      if (params.data?.orderId) {
        return params.data.orderId;
      }
    }
    const orderIdDisp = useMemo(() => getOrderId(), [params.data.po_id]);
    return (
      <Typography fontSize="10px" fontWeight={600}>
        <NavLink
          onClick={() => {
            if (params.data.invoice?.id) {
              return (window.location.href = `/invoices/invoices/${params.data.invoice.id}`);
            }
            showModal((props) => (
              <ErrorBoundary fallback={<ErrorPopupBoundary />}>
                <OrderItemsModal
                  accountId={params.data.accountId}
                  date={params.data.date}
                  orderId={params.data.orderId}
                  auId={params.data.auId}
                  orderPoId={params.data.po_id}
                  refreshTable={refreshTable}
                  manageOrderClick={manageOrderClick}
                  orderObj={params.data}
                  {...props}
                />
              </ErrorBoundary>
            ));
          }}
        >
          {orderIdDisp}
        </NavLink>
      </Typography>
    );
  };

  const ItemsRenderer = (params) => {
    // const handleNavigateToClonedOrder = (date, account) => {
    //   navigate(`/orders?date=${dayjs.utc(date).format(DATE_FORMAT)}&search=${account}`)
    // }

    const manageOrderClick = () => {
      const date = params.data.date;
      const accountId = params.data.accountId;
      const day = dayjs.utc(date).format("ddd").toLowerCase();
      navigate(
        `/orders/manage-auto-orders?date=${date}&accountId=${accountId}&day=${day}`
      );
    };

    const handleOpen = () => {
      setOrderToCopy(params.data);
    };
    function containsUnusualQty() {
      let unusual = false;
      const unusual_qtys_text = [];
      params.data?.items?.map((item) => {
        if (item?.unusual_qty) {
          unusual = true;
          unusual_qtys_text.push(
            `${item.display_name || item?.item_details?.name} ${item?.unusual_qty_msg}`
          );
        }
      });
      return {
        unusual,
        unusual_qtys_text,
      };
    }
    const unusualQty = useMemo(() => containsUnusualQty(), [params.data]);
    return (
      <Button
        size="small"
        variant="gray"
        endIcon={<OpenIc />}
        sx={{
          bgcolor: "#F5F5F5",
          border: "1px solid #DBDFEA !important",
          padding: "0 9px !important",
          fontSize: "14px !important",
        }}
        onClick={() => {
          if (params.data?.state == "DELIVERED") {
            window.location.href = `/invoices/invoices/${params.data.invoice.id}`;
            return;
          }
          showModal((props) => (
            <ErrorBoundary fallback={<ErrorPopupBoundary />}>
              <OrderItemsModal
                items={params.data?.items || {}}
                accountId={params.data.accountId}
                date={params.data.date}
                orderId={params.data.orderId}
                orderPoId={params.data.po_id}
                refreshTable={refreshTable}
                manageOrderClick={manageOrderClick}
                orderObj={params.data}
                auId={params.data.auId}
                handleOpenCopyOrder={handleOpen}
                {...props}
              />
            </ErrorBoundary>
          ));
        }}
      >
        {unusualQty?.unusual && (
          <HtmlTooltip
            title={
              <div>
                <Typography fontWeight={800} color={"red"}>
                  Unusual QTYS:
                </Typography>
                {unusualQty?.unusual_qtys_text.map((item, i) => (
                  <Typography key={i}>{item}</Typography>
                ))}
              </div>
            }
          >
            <IconButton>
              <Flag color="warning" />
            </IconButton>
          </HtmlTooltip>
        )}
        {params.value.length}
      </Button>
    );
  };
  const AccountRenderer = (params) => {
    return (
      <Box
        bgcolor="#F5F5F5"
        border="1px solid #DBDFEA"
        borderRadius="5px"
        p="2px 9px"
        onClick={() =>
          navigate(`/customers/dashboard/${params.data.customerId}/personal`)
        }
      >
        {params.data.account}
      </Box>
    );
  };
  const PaymentStatusRenderer = (params) => {
    return (
      <Box
        bgcolor="#F5F5F5"
        border="1px solid #DBDFEA"
        borderRadius="5px"
        p="2px 9px"
        sx={{
          "::before": {
            content: "''",
            display: "inline-flex",
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            bgcolor: "#8F8F8F",
            marginRight: "4px",
          },
        }}
      >
        {paymentStatus[params.data.invoice?.status] || "Pending"}
      </Box>
    );
  };

  const FulfillmentStatusRenderer = (params) => {
    function getBgColor() {
      if (params.data.state == "CANCELED") {
        return "#ff9898";
      }
      if (params.data.state == "DELIVERED") {
        return "#47f28a";
      }
      if (
        params.data.state == "CUSTOMER_EDIT" ||
        params.data.state == "CUSTOMER_PENDING"
      ) {
        return "orange";
      }
      if (params.data.state == "CUSTOMER_CANCEL") {
        return "pink";
      }
      if (params.data.state == "APPROVED") {
        return "lightgreen";
      }
      if (params.data.state == "PICKED_UP") {
        return "lightblue";
      }
      if (!params.data.state) {
        return "#F6F6F7";
      }
      return "#FEF9CE";
    }

    return (
      <Box
        bgcolor={getBgColor()}
        borderRadius="5px"
        border="1px solid #FEF9CE"
        p="2px 9px"
        sx={{
          "::before": {
            content: "''",
            display: "inline-flex",
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            bgcolor: "#8F8F8F",
            marginRight: "4px",
          },
        }}
      >
        {fulfillmentStatus[params.data.state] || "Automatic"}
      </Box>
    );
  };

  const ApprovalStatusRenderer = (params) => {
    let pendingCount = -1;
    if (params.data.orderId) {
      pendingCount = params.data.items.filter(
        (item) => item.status === "PENDING"
      ).length;
    }
    return (
      <HtmlTooltip
        title={
          <div>
            {pendingCount == -1 && (
              <Typography color="inherit">
                Not applicable (future order)
              </Typography>
            )}
            {pendingCount > 0 && (
              <Typography color="inherit">
                {pendingCount} items need a decision
              </Typography>
            )}
            {pendingCount == 0 && (
              <Typography color="inherit">All set</Typography>
            )}
          </div>
        }
      >
        <Box
          bgcolor="#F5F5F5"
          borderRadius="5px"
          border="1px solid #FEF9CE"
          p="2px 9px"
          sx={{
            "::before": {
              content: "''",
              display: "inline-flex",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              bgcolor: pendingCount == 0 ? "green" : "red",
              marginRight: "4px",
            },
          }}
        >
          {pendingCount == -1 && "0/" + params.data.items.length}
          {pendingCount >= 0 &&
            `${
              params.data.items.filter((item) => item.status != "CANCELED")
                .length - pendingCount
            }/${
              params.data.items.filter((item) => item.status != "CANCELED")
                .length
            }`}
        </Box>
      </HtmlTooltip>
    );
  };

  const LinkRenderer = (params) => {
    if (!params.data.orderId) return <></>;

    return (
      <IconButton onClick={() => setOrderToCopy(params.data)}>
        <ContentCopyIcon size="small" />
      </IconButton>
    );
  };

  const columnDefs = [
    {
      headerName: "Order",
      field: "accountId",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      minWidth: 110,
      cellRenderer: OrdersRenderer,
      cellStyle: defaultCellStyle,
      filter: false,
    },
    {
      headerName: "Fulfillment date",
      valueGetter: (params) => {
        return dayjs.utc(params.data.date).format(DATE_FORMAT_FRONT);
      },
      minWidth: 150,
      cellStyle: defaultCellStyle,
      filter: false,
    },
    {
      headerName: "Account",
      field: "account",
      minWidth: 350,
      cellStyle: defaultCellStyle,
      filter: false,
      cellRenderer: AccountRenderer,
      sort: "asc",
      sortable: true,
    },
    {
      headerName: "Route",
      minWidth: 175,
      cellRenderer: (params) =>
        RouteRenderer(params, { refreshTable, showModal }),
      wrapText: true,
      autoHeight: true,
      cellStyle: defaultCellStyle,
      filter: false,
      sortable: true,
    },
    {
      headerName: "Total",
      field: "total",
      valueGetter: (params) => "$" + Number(params.data.total).toFixed(2),
      minWidth: 120,
      cellStyle: defaultCellStyle,
      filter: false,
      sortable: true,
    },
    {
      headerName: "Payment Status",
      field: "paymentStatus",
      minWidth: 150,
      cellRenderer: PaymentStatusRenderer,
      cellStyle: defaultCellStyle,
      filter: false,
      sortable: true,
    },
    {
      headerName: "Fulfillment Status",
      field: "state",
      minWidth: 160,
      cellRenderer: FulfillmentStatusRenderer,
      cellStyle: defaultCellStyle,
      filter: false,
      sortable: true,
    },
    {
      headerName: "Items",
      field: "items",
      minWidth: 100,
      cellRenderer: ItemsRenderer,
      cellStyle: defaultCellStyle,
      filter: false,
      sortable: true,
    },
    {
      headerName: "Approval Status",
      field: "deliveryMethod",
      minWidth: 150,
      cellRenderer: ApprovalStatusRenderer,
      cellStyle: defaultCellStyle,
      filter: false,
      sortable: true,
    },
    {
      minWidth: 160,
      maxWidth: 160,
      cellRenderer: LinkRenderer,
      cellStyle: defaultCellStyle,
      filter: false,
    },
  ];
  return (
    <Box
      className="table-wrapper routes-table"
      sx={{
        "& .ag-header-cell": { paddingLeft: "15px", paddingRight: "15px" },
      }}
    >
      <Dialog
        open={orderToCopy}
        onClose={() => {
          setOrderToCopy(false);
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="confirm-dialog">
          <Typography component="h3" fontSize="24px">
            Copy order
          </Typography>{" "}
        </DialogTitle>

        <OrderItemsModalCopy
          accountId={orderToCopy?.accountId}
          date={orderToCopy?.date}
          orderId={orderToCopy?.orderId}
          auId={orderToCopy?.auId}
          orderPoId={orderToCopy?.orderPoId}
          refreshTable={refreshTable}
          manageOrderClick={orderToCopy?.manageOrderClick}
          orderObj={orderToCopy?.orderObj}
          handleClose={() => {
            setOrderToCopy(false);
          }}
        />
      </Dialog>
      <DataTable
        rowData={items}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowHeight={48}
        headerHeight={66}
      />
    </Box>
  );
};

export default OrdersTable;
