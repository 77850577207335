import { useModal } from "mui-modal-provider";
import {
  createOrder,
  deletetOrder,
  emailOrderCopy,
  fetchItemRoute,
  getAutomaticOrder,
  getOrder,
  listAutomaticOrders,
  listVariants,
  updateOrder,
} from "../../distflowAPI/ordersApi";
import React, { useEffect, useMemo, useState, useRef } from "react";
import DatePicker from "../DatePicker2";
import Product from "../../pages/manageAutoOrders/Product";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import Search from "../../images/search.svg";
import "../../pages/orders/styles.css";
import utc from "dayjs/plugin/utc";
import Spinner from "../Spinner";
import AccountSelect from "../AsyncSelects/AccountSelect";
import SalesmanSelect from "../AsyncSelects/SalesmanSelect";
import { DATE_FORMAT } from "../../utils/constants";
import OrderTitleModal from "./OrderTitleModal";
import OrderItemsModalItem from "./OrderItemsModalItem";
import OrderItemWrapper from "./OrderItemWrapper";
import RouteSelect from "../AsyncSelects/RouteSelect";
import useAppDispatch from "../../hooks/useAppDispatch";
import { cleanAccountData, fetchAccount } from "../../redux/module/account";
import PageLoading from "../PageLoading";
import ConfirmDialog from "../ConfirmDialog";
import { dynamicSort, renderErrors, useSearchDebounce } from "../../utils";
import OrderItemTotal from "./OrderItemTotal";
import ImportOrderModal from "./ImportOrderModal";
import { downloadFile } from "../../distflowAPI/downloadFile";
import NotesModal from "../NotesModal";
import { useHotkeys } from "react-hotkeys-hook";
import { HotkeysProvider } from "react-hotkeys-hook";
import HistoryModal from "../../pages/invoices/components/HistoryModal";
dayjs.extend(utc);

const OrderItemsModal = (props) => {
  const { showModal } = useModal();

  const [orderObj, setOrderObj] = useState({});
  const [shippingAddress, setShippingAddress] = useState(
    orderObj?.orderAddress || ""
  );
  const [attributedSalesMan, setAttributedSalesMan] = useState({
    label: orderObj?.attributed_sales_man?.name || "",
    value: orderObj?.attributed_sales_man?.id || "",
  });
  const { orderId, auId } = props;
  useEffect(() => {
    console.log("order, auId", orderId, auId, props);
    if (orderId) {
      setOrderLoading(true);
      getOrder(orderId)
        .then((res) => setOrderObj(res.data))
        .finally(() => setOrderLoading(false));
    }
    if (auId) {
      setOrderLoading(true);
      getAutomaticOrder(auId)
        .then((res) => setOrderObj(res.data))
        .finally(() => setOrderLoading(false));
    }
  }, [props.orderId, props.auId]);
  useEffect(() => {
    setShippingAddress(orderObj?.orderAddress || "");
    setAttributedSalesMan({
      label: orderObj?.attributed_sales_man?.name || "",
      value: orderObj?.attributed_sales_man?.id || "",
    });
  }, [orderObj]);
  function setAddress(val) {
    setShippingAddress(val);
  }
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const dispatch = useAppDispatch();
  const { ...restProps } = props;
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [items, setItems] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const [variantsLoading, setVariantsLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [errors, setErrors] = useState([]);
  const [existingOrdersCount, setExistingOrdersCount] = useState(0);
  const [itemToAdd, setItemToAdd] = useState(null);
  const [filterVariant, setFilterVariant] = useSearchDebounce();
  const [allItemsRoute, setAllItemsRoute] = useState({
    value: null,
    label: "",
  });
  const [account, setAccount] = useState({
    label: "",
    value: props.isCreate ? null : props.accountId,
  });
  const [orderState, setOrderState] = useState("");
  const [date, setDate] = useState(
    props.date ? dayjs.utc(props.date) : dayjs(new Date())
  );
  const [variantSearch, setVariantSearch] = useState("");
  const [variants, setVariants] = useState([]);
  const [productsPage, setProductsPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [importOpen, setImportOpen] = useState(false);
  const [isEmptyQuery, setIsEmptyQuery] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);

  const [isSalesmanSelect, setIsSalesmanSelect] = useState(true);
  const changeItemVal = (itemId, newVal) => {
    const newItems = { ...items };
    console.log("new Items: ", newItems);
    newItems[itemId] = { ...newItems[itemId], ...newVal };
    setItems(newItems);
  };

  const changeItemRoute = (itemId, newVal) => {
    const newItems = { ...items };
    newItems[itemId] = { ...newItems[itemId], ...newVal };
    setItems(newItems);
  };

  const handleChangeAllItemsRoute = (newValue) => {
    if (newValue) {
      const newItems = { ...items };
      for (const key in newItems) {
        if (newItems.hasOwnProperty(key)) {
          newItems[key] = {
            ...newItems[key],
            route: { id: newValue.value, name: newValue.label },
            manual_route: true,
          };
        }
      }
      setItems(newItems);
    }
  };

  const approveAllItems = (status) => {
    const newItems = { ...items };
    for (const key in newItems) {
      if (newItems.hasOwnProperty(key)) {
        const item = newItems[key];
        if (item.status === status) {
          item.status = "APPROVED";
        }
      }
    }
    setItems(newItems);
  };

  const cancelAllItems = (status) => {
    const newItems = { ...items };
    for (const key in newItems) {
      if (newItems.hasOwnProperty(key)) {
        const item = newItems[key];
        if (item.status === status) {
          item.status = "CANCELED";
        }
      }
    }
    setItems(newItems);
  };

  const saveChanges = () => {
    setIsSaving(true);
    setErrors([]);
    // in case we're modifying an actual order
    const payload = {
      items: [],
      account_id: props.accountId || account.value,
      date: date.format(DATE_FORMAT),
      fulfillment_date: date.format(DATE_FORMAT),
      orderAddress: shippingAddress || undefined,
      creation_mode: props.isCreate ? "MANUAL" : "AUTO",
      attributed_sales_man: attributedSalesMan?.value,
    };
    Object.keys(items).map((item) => {
      payload["items"].push({
        variant: items[item].item,
        unit: items[item].unit || items[item]?.item_details?.sold_by,
        quantity: items[item].qty,
        status:
          items[item].status === "AUTO_APPROVE"
            ? "APPROVED"
            : items[item].status,
        invoice_note: items[item].invoice_note,
        route: items[item].route?.id,
        manual_route: items[item].manual_route,
        discount_type: items[item].discount_type || null,
        discount_value:
          (parseFloat(items[item].discount_value) || 0).toFixed(2) || 0,
        discount_note: items[item].discount_note || null,
        display_name: items[item].display_name || items[item].name,
        unit_cost: items[item].unit_cost,
        unit_price: items[item].unit_price,
      });
    });
    payload["email_order"] = emailOrder;
    if (props.orderId) {
      updateOrder(props.orderId, payload)
        .then(
          (res) => {
            props.refreshTable(true);
            props.onClose();
          },
          (error) => {
            try {
              const errorField = Object.keys(error.response.data);
              const errorMessage = error.response.data[errorField][0];
              setErrors((prev) => [...prev, `${errorField}: ${errorMessage}`]);
            } catch (e) {}
          }
        )
        .finally(() => setIsSaving(false));
    } else {
      payload["create_new"] = true;
      payload["email_order"] = emailOrder;
      payload["force_create"] = forceCreate;
      payload["created_from_auto"] = auId;
      createOrder(payload)
        .then(
          (res) => {
            props.refreshTable(true);
            props.onClose();
          },
          (error) => {
            setErrors(error.response.data);
          }
        )
        .finally(() => setIsSaving(false));
    }

    // in case we're processing an automatic order
  };

  const disableSaturday = (date) => {
    const currentDate = dayjs.utc(date).format("ddd");
    return currentDate === "Sat";
  };

  const loadMore = async () => {
    setIsProductsLoading(true);
    await listVariants(
      10,
      productsPage + 1,
      [
        { value: account.value, column: "accountId" },
        { value: variantSearch, column: "search" },
      ],
      null
    ).then((res) => {
      const newVariants = res.results.map((v) => {
        const item = {
          ...v,
          label: v.name,
          ordered: false,
          ordered_pc: false,
        };
        if (items[item.id + "-" + item.sold_by + "-"]) {
          item.ordered = true;
        }
        if (items[item.id + "-PC" + "-"]) {
          item.ordered_pc = true;
        }
        return item;
      });
      setVariants((prev) => [...prev, ...newVariants]);
      setProductsPage((prev) => prev + 1);
      setIsProductsLoading(false);
    });
  };

  const handleCustomerAccountChange = (event, newValue) => {
    if (newValue.value) {
      setAccount(newValue);
    } else {
      setAccount({ label: "", value: null });
    }
  };
  const handleSalesManChange = (event, newValue) => {
    if (newValue.value) {
      setAttributedSalesMan(newValue);
    } else {
      setAttributedSalesMan({ label: "", value: null });
    }
  };
  const itemsArr = useMemo(() => {
    return Object.keys(items).map((key) => items[key]);
  }, [items]);

  const pendingItems = useMemo(() => {
    return itemsArr.filter((oi) => oi.status === "PENDING");
  }, [itemsArr]);

  const approvedItems = useMemo(() => {
    return itemsArr
      .filter((oi) => oi.status === "APPROVED")
      .sort((a, b) => (a?.item_details?.sku > b?.item_details?.sku ? 1 : -1));
  }, [itemsArr]);

  const autoApprovedItems = useMemo(() => {
    return itemsArr.filter((oi) => oi.status === "AUTO_APPROVE");
  }, [itemsArr]);

  const deliveredItems = useMemo(() => {
    return itemsArr.filter((oi) => oi.status === "DELIVERED");
  }, [itemsArr]);

  const pickedUpItems = useMemo(() => {
    return itemsArr.filter((oi) => oi.status === "PICKED_UP");
  }, [itemsArr]);

  const canceledItems = useMemo(() => {
    return itemsArr.filter((oi) => oi.status === "CANCELED");
  }, [itemsArr]);

  useEffect(() => {
    if (props.isCreate && props.withCustomerAccounts) {
      setAccount(props.account);
    }
  }, []);

  useEffect(() => {
    if (account && account.value) {
      dispatch(fetchAccount(account.value));
    } else {
      dispatch(cleanAccountData());
    }
  }, [account]);

  useEffect(() => {
    if (account && account.value && !orderId && !auId) {
      setOrderLoading(true);
      listAutomaticOrders(date.format(DATE_FORMAT), account.value)
        .then((res) => {
          setExistingOrdersCount(res.results.length);
        })
        .finally(() => {
          setOrderLoading(false);
        });
    }
  }, [account, date]);

  useEffect(() => {
    if (!variantSearch) {
      setVariants([]);
    }
  }, [variantSearch]);

  function updateVariantsList() {
    if (errors.length) setErrors([]);
    setProductsPage(1);
    if (account && account.value) {
      setVariantsLoading(true);
      listVariants(
        10,
        productsPage,
        [
          { value: account.value, column: "accountId" },
          { value: variantSearch, column: "search" },
        ],
        null
      )
        .then((res) => {
          if (res.count === 0) {
            setIsEmptyQuery(true);
          }
          const newVariants = res.results.map((v) => {
            const item = {
              ...v,
              label: v.name,
              ordered: false,
              ordered_pc: false,
              vendor_details: {
                vendorName: v.vendor,
              },
            };
            if (items[item.id + "-" + item.sold_by + "-" + item.name]) {
              item.ordered = true;
            }
            if (items[item.id + "-PC-" + item.name]) {
              item.ordered_pc = true;
            }
            return item;
          });
          setVariants(newVariants);
          setTotalPages(Math.ceil(res.count / 10));
        })
        .finally(() => {
          setVariantsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setVariants([]);
    }
  }

  useEffect(() => {
    if (productsPage > 1) {
      setProductsPage(1);
    }
    // updateVariantsList();
  }, [account, date]);
  useEffect(() => {
    setFilterVariant(variantSearch);
  }, [variantSearch]);
  /* useEffect(() => {
    if (account && date) {
      updateVariantsList();
    }
  }, [filterVariant]); */

  useEffect(() => {
    if (variants && items) {
      const newVariants = variants.map((v) => {
        const item = { ...v };
        if (items[item.id + "-" + item.sold_by + "-"]) {
          item.ordered = true;
        }
        if (items[item.id + "-PC-"]) {
          item.ordered_pc = true;
        }
        return item;
      });
      setVariants(newVariants);

      const itemsKeys = Object.keys(items);
      const itemsRoutesId = itemsKeys.map((el) => items[el]?.route?.id || "");
      const isAllRoutesSame = itemsRoutesId.every(
        (element) => element === itemsRoutesId[0]
      );
      if (isAllRoutesSame) {
        setAllItemsRoute({
          value: items[itemsKeys[0]]?.route?.id || null,
          label: items[itemsKeys[0]]?.route?.name || "",
        });
      }
    }
  }, [items]);
  useEffect(() => {
    const originalItems =
      JSON.parse(JSON.stringify(orderObj.items || [])) || [];
    const newItems = {};
    originalItems.map((item) => {
      newItems[`${item.item}-${item.unit}-${item.display_name || ""}`] = item;
    });
    if (Object.keys(items).length == 0) {
      setItems(newItems);
    }
  }, [orderObj]);

  function addItem(data, index, qty, displayName = "", isCustom = false) {
    setItems({
      ...items,
      [data.item.id +
      "-" +
      (data.unit || data.item.sold_by) +
      "-" +
      (displayName || data.item.name)]: {
        item_details: data.item,
        qty,
        item: data.item.id,
        unit: data.unit || data.item.sold_by,
        status: "APPROVED",
        display_name: displayName || data.item.name,
        route: {
          name: "TBD",
          id: null,
        },
      },
    });
    let newVariants = [...variants];
    if (data.unit) {
      newVariants[index]["ordered_pc"] = true;
    } else {
      newVariants[index]["ordered"] = true;
    }
    setVariantSearch("");
    setVariants([]);
    setCurrentIndex(0);
  }

  const [itemsRoutes, setItemsRoutes] = useState({});
  useEffect(() => {
    Object.keys(items).map((key) => {
      if (items[key]?.route?.name == "TBD") {
        fetchItemRoute(
          items[key].item,
          account.value,
          date.format("YYYY-MM-DD")
        ).then((res) => {
          setItemsRoutes({ ...itemsRoutes, [key]: res.data });
        });
      }
    });
  }, [items]);
  useEffect(() => {
    const newItemsRoutes = { ...itemsRoutes };
    Object.keys(items).map((key) => {
      newItemsRoutes[key] = { id: null, name: "TBD" };
    });
    setItemsRoutes(newItemsRoutes);
    Object.keys(items).map((key) => {
      if (items[key]?.route?.name == "TBD") {
        fetchItemRoute(
          items[key].item,
          account.value,
          date.format("YYYY-MM-DD")
        ).then((res) => setItemsRoutes({ ...itemsRoutes, [key]: res.data }));
      }
    });
  }, [date]);
  useEffect(() => {}, [itemsRoutes]);
  const [confirmCloseOpen, setConfirmCloseOpen] = useState(false);
  const [emailOrder, setEmailOrder] = useState(false);
  const [forceCreate, setForceCreate] = useState(false);

  ///////////////////////
  const searchRef = useRef(null);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemRefs = useRef([]);
  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, variants.length);
  }, [variants]);

  const scrollToItem = (index) => {
    itemRefs.current[index]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };
  useHotkeys(
    "down",
    () => {
      console.log("vartiant length is", variants.length);
      console.log("currentindex is", currentIndex);
      if (currentIndex > variants.length - 3) {
        loadMore();
      }
      setCurrentIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % variants.length;
        scrollToItem(nextIndex);
        return nextIndex;
      });
    },
    [variants.length, currentIndex]
  );

  useHotkeys(
    "up",
    () => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = (prevIndex - 1 + variants.length) % variants.length;
        scrollToItem(nextIndex);
        return nextIndex;
      });
    },
    [variants.length]
  );
  useHotkeys(
    "s",
    (e) => {
      e.preventDefault();
      if (searchRef.current) {
        searchRef.current.focus();
      }
    },
    [searchRef]
  );
  useHotkeys(
    "enter",
    () => {
      setCurrentIndex(0);
      console.log("variants are", variants);
      if (!itemToAdd && variantSearch && variants.length > 0) {
        let index = currentIndex;
        setItemToAdd({
          itemToAdd: { item: variants[currentIndex], account: account },
          index,
        });
      }
    },
    [currentIndex, variants, account, variantSearch]
  );
  /////////////////////
  const [showCanceled, setShowCanceled] = useState(false);
  return (
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      maxWidth="xl"
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "1200px",
        },
        zIndex: 1500,
      }}
      onClose={() => {}}
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{ p: "2rem 2rem 1rem 3rem" }}
      >
        <Stack direction="row" alignItems="flex-end" sx={{ width: "100%" }}>
          <OrderTitleModal
            state={orderState}
            refreshTable={props.refreshTable}
            orderId={props.orderId}
            invoiceId={orderObj?.invoice?.id}
            orderPoId={props.orderPoId}
            date={date}
            shippingAddress={shippingAddress}
            setShippingAddress={setShippingAddress}
          />
        </Stack>
        <IconButton
          onClick={() => props.onClose()}
          sx={{
            color: "#515151",
            marginRight: "-5px",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>

      <Box
        sx={{
          padding: "0 2rem",
          width: "100%",
          height: "100%",
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <PageLoading dataLoading={orderLoading || variantsLoading} />
          {isSaving && (
            <Alert
              sx={{
                position: "sticky",
                top: "0",
                zIndex: 5000,
              }}
            >
              Saving...
            </Alert>
          )}
          <Box sx={{ padding: "18px 18px 22px 18px" }}>
            <Stack>
              {success && <Alert>success</Alert>}
              {!!Object.keys(errors).length && (
                <Alert severity="error">{renderErrors(errors)}</Alert>
              )}
              <Stack>
                {props.isCreate && (
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                      <Typography
                        fontSize="14px"
                        component="p"
                        variant="label"
                        mb="9px"
                      >
                        Date
                      </Typography>

                      <DatePicker
                        controlledValue={date}
                        setControlledValue={setDate}
                        required
                        minDate={dayjs(new Date()).add(1, "day")}
                        shouldDisableDate={disableSaturday}
                      />
                      {date.format("YYYY-MM-DD") ===
                        dayjs.utc(new Date()).format("YYYY-MM-DD") && (
                        <Alert
                          severity={"warning"}
                          sx={{ padding: "0 10px", mt: "4px" }}
                        >
                          You are making an order for today
                        </Alert>
                      )}
                    </Box>

                    <ImportOrderModal
                      importOpen={importOpen}
                      setImportOpen={setImportOpen}
                      account={account}
                      setItemsCopy={setItems}
                      setAddressCopy={setAddress}
                    />
                  </Box>
                )}
                {props.isCreate && !props.withCustomerAccounts && (
                  <Box mt={1}>
                    <Typography
                      fontSize="14px"
                      component="p"
                      variant="label"
                      mb="9px"
                    >
                      Account
                    </Typography>
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                    >
                      <AccountSelect
                        name="account"
                        value={account}
                        fullWidth
                        onChange={(event, newValue) => {
                          setAccount(null);
                          setAttributedSalesMan({ value: "", label: "" });
                          setIsSalesmanSelect(true);
                          if (newValue) {
                            if (newValue.value) {
                              setAccount(newValue);
                              if (newValue.attributed_sales_man) {
                                setIsSalesmanSelect(false);
                                console.log("TRUUEE");
                                setAttributedSalesMan({
                                  value: newValue.attributed_sales_man.id,
                                  label: newValue.attributed_sales_man.name,
                                });
                              }
                            }
                          }
                        }}
                      />
                    </Box>
                  </Box>
                )}
                {props.isCreate && account?.value && (
                  <Button
                    variant="contained"
                    sx={{ mt: "10px", mb: "10px" }}
                    color="info"
                    onClick={() => {
                      setImportOpen(true);
                    }}
                  >
                    Copy from a previous order
                  </Button>
                )}
                {props.isCreate && props.withCustomerAccounts && (
                  <Box mt={1}>
                    <Typography
                      fontSize="14px"
                      component="p"
                      variant="label"
                      mb="9px"
                    >
                      Customer Accounts
                    </Typography>
                    <Autocomplete
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          data-testid={`customer-accounts-select-id`}
                        />
                      )}
                      classes={{ popper: "autocomplete-popper" }}
                      value={account}
                      onChange={handleCustomerAccountChange}
                      options={props?.customerAccounts
                        .map((el) => {
                          return {
                            value: el.id,
                            label: el.name,
                          };
                        })
                        .filter(Boolean)}
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "0 65px 0 9px",
                        },
                        "& .MuiInputBase-input": {
                          padding: "8.5px 4px 8.5px 5px !important",
                        },
                        "& .MuiFormControl-root": {
                          minHeight: "40px !important",
                        },
                      }}
                    />
                  </Box>
                )}

                <Box>
                  <Box
                    mt={1}
                    component="form"
                    sx={{
                      width: "100%",
                      "& .MuiFormControl-root": {
                        width: "100%",
                        minHeight: "40px",
                      },
                    }}
                  >
                    <TextField
                      placeholder="Press enter to search for your products..."
                      data-testid="test-search-id"
                      inputRef={searchRef}
                      value={variantSearch}
                      onChange={(e) => {
                        setVariantSearch(e.target.value);
                        setIsEmptyQuery(false);
                      }}
                      onFocus={() => setIsSearchFocused(true)}
                      onBlur={() => setIsSearchFocused(false)}
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          if (account && date && variantSearch) {
                            updateVariantsList();
                          }
                          if (itemRefs.current[0]) {
                            console.log("current", itemRefs.current[0]);
                            itemRefs.current[0].focus();
                          }
                          if (searchRef.current) {
                            searchRef.current.blur();
                          }
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <img src={Search} alt="" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  {(variantsLoading || orderLoading) && (
                    <Stack
                      justifyContent={"center"}
                      justifyItems={"center"}
                      alignContent={"center"}
                      alignItems={"center"}
                      sx={{ marginTop: 4 }}
                    >
                      <Spinner size="md" />
                    </Stack>
                  )}
                  {variants.length === 0 && variantSearch && isEmptyQuery && (
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontStyle: "italic",
                        padding: "10px 0",
                        color: "red",
                      }}
                    >
                      No items found
                    </Typography>
                  )}
                  {variantSearch && !variantsLoading && (
                    <Box
                      sx={{
                        maxHeight: "400px",
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        mt: 1,
                        paddingRight: "5px",
                        "&::-webkit-scrollbar": {
                          borderRadius: "10px",
                          width: "10px",
                          height: "10px",
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "#dddddd",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          boxShadow: "inset 0 0 6px rgba(155, 145, 250, 0.3)",
                          backgroundColor: "#95989a",
                          borderRadius: "10px",
                        },
                      }}
                    >
                      {variants.map((item, index) => (
                        <Box
                          tabIndex="0"
                          sx={{
                            border:
                              index === currentIndex ? "2px solid green" : "",
                            "&:focus": {
                              outline: "none",
                            },
                          }}
                          ref={(el) => (itemRefs.current[index] = el)}
                          key={`vb-${index}`}
                        >
                          <Product
                            parent
                            item={item}
                            account={account}
                            // add item modal
                            customAdd={(data) => {
                              setItemToAdd({ itemToAdd: data, index });
                            }}
                            showView
                            key={`vp-${index}`}
                            date={date}
                          />
                        </Box>
                      ))}
                      {variantSearch &&
                        !!variants.length &&
                        productsPage < totalPages && (
                          <Button
                            sx={{
                              display: "flex",
                              margin: "10px auto",
                              height: "40px",
                            }}
                            onClick={loadMore}
                            disabled={isProductsLoading}
                          >
                            {isProductsLoading ? (
                              <Spinner size="md" />
                            ) : (
                              "Load more..."
                            )}
                          </Button>
                        )}
                    </Box>
                  )}
                </Box>
              </Stack>
              <Stack direction="column" gap={1}>
                <RouteSelect
                  name="route"
                  placeholder="Select route for all items"
                  // disabled={item.status === "DELIVERED" || item.status === "PICKED_UP"}
                  value={allItemsRoute}
                  onChange={(_e, newValue) => {
                    handleChangeAllItemsRoute(newValue);
                  }}
                  extraStyles={{
                    mt: 1,
                  }}
                />
              </Stack>
            </Stack>

            {existingOrdersCount > 0 && props.isCreate && (
              <Alert severity="warning" sx={{ marginTop: 2 }}>
                {existingOrdersCount} order{existingOrdersCount > 1 ? "s" : ""}{" "}
                for this account already exist, did you mean to edit one of the
                existing orders?
              </Alert>
            )}

            <Stack diregtion="column" gap={2}>
              {pendingItems.length > 0 && (
                <OrderItemWrapper
                  status="pending"
                  count={pendingItems.length}
                  cancelAllItems={cancelAllItems}
                  approveAllItems={approveAllItems}
                >
                  {pendingItems.sort(dynamicSort("item")).map((item, i) => (
                    <OrderItemsModalItem
                      key={`pi-${i}`}
                      item={item}
                      changeItemVal={changeItemVal}
                      changeItemRoute={changeItemRoute}
                    />
                  ))}
                </OrderItemWrapper>
              )}
              {autoApprovedItems.length > 0 && (
                <OrderItemWrapper
                  status="auto"
                  count={autoApprovedItems.length}
                  cancelAllItems={cancelAllItems}
                  approveAllItems={approveAllItems}
                >
                  {autoApprovedItems
                    .sort(dynamicSort("item"))
                    .map((item, i) => (
                      <OrderItemsModalItem
                        key={`aup-${i}`}
                        item={item}
                        changeItemVal={changeItemVal}
                        changeItemRoute={changeItemRoute}
                      />
                    ))}
                </OrderItemWrapper>
              )}
              {approvedItems.length > 0 && (
                <OrderItemWrapper
                  status="approved"
                  count={approvedItems.length}
                >
                  {approvedItems.map((item, i) => (
                    <OrderItemsModalItem
                      key={`ap-${i}`}
                      item={item}
                      changeItemVal={changeItemVal}
                      changeItemRoute={changeItemRoute}
                      itemsRoutes={itemsRoutes}
                    />
                  ))}
                </OrderItemWrapper>
              )}
              {deliveredItems.length > 0 && (
                <OrderItemWrapper
                  status="delivered"
                  count={deliveredItems.length}
                >
                  {deliveredItems.sort(dynamicSort("item")).map((item, i) => (
                    <OrderItemsModalItem
                      key={`dp-${i}`}
                      item={item}
                      changeItemVal={changeItemVal}
                      changeItemRoute={changeItemRoute}
                    />
                  ))}
                </OrderItemWrapper>
              )}
              {pickedUpItems.length > 0 && (
                <OrderItemWrapper status="picked" count={pickedUpItems.length}>
                  {pickedUpItems.sort(dynamicSort("item")).map((item, i) => (
                    <OrderItemsModalItem
                      key={`pp-${i}`}
                      item={item}
                      changeItemVal={changeItemVal}
                      changeItemRoute={changeItemRoute}
                    />
                  ))}
                </OrderItemWrapper>
              )}
              {canceledItems.length > 0 && (
                <OrderItemWrapper
                  status="cancelled"
                  count={canceledItems.length}
                  showCanceled={showCanceled}
                  setShowCanceled={setShowCanceled}
                >
                  {showCanceled &&
                    canceledItems
                      .sort(dynamicSort("item"))
                      .map((item, i) => (
                        <OrderItemsModalItem
                          key={`cp-${i}`}
                          item={item}
                          changeItemVal={changeItemVal}
                          changeItemRoute={changeItemRoute}
                        />
                      ))}
                </OrderItemWrapper>
              )}
            </Stack>
            <OrderItemTotal
              items={items}
              attributedSalesMan={attributedSalesMan}
              setAttributedSalesMan={handleSalesManChange}
              canAttributeSalesMan={props}
            >
              {" "}
              <SalesmanSelect
                label="Select a Salesman"
                name="salesman"
                required
                disabled={!isSalesmanSelect}
                value={attributedSalesMan}
                onChange={(_e, newValue) => setAttributedSalesMan(newValue)}
                extraStyles={{
                  "& .MuiInputLabel-root": {
                    fontWeight: "lighter",
                  },
                }}
              />
            </OrderItemTotal>

            <DialogActions sx={{ marginTop: "16px" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
              >
                <Stack direction="row" spacing={1}>
                  {props.orderId && (
                    <Button
                      onClick={() => {
                        props.handleOpenCopyOrder();
                        props.onClose();
                      }}
                      variant="outlined"
                    >
                      Reorder
                    </Button>
                  )}
                  {props.orderId && (
                    <Button
                      variant={"contained"}
                      color={"success"}
                      onClick={() =>
                        downloadFile(
                          `/api/orders/download_receipt/?orderId=${props.orderId}`,
                          `INV-#${orderObj?.invoice?.id}.pdf`
                        )
                      }
                    >
                      Download
                    </Button>
                  )}
                  {props.orderId && (
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      onClick={() =>
                        emailOrderCopy(props.orderId).then((res) =>
                          alert("Email sent")
                        )
                      }
                    >
                      Email
                    </Button>
                  )}
                  {props.manageOrderClick && (
                    <Button
                      className="btn-height sx-full-width"
                      onClick={props.manageOrderClick}
                      color="success"
                      variant="text"
                    >
                      Manage Steadies
                    </Button>
                  )}
                  {!props.orderId && (
                    <FormControlLabel
                      label="Email client?"
                      control={
                        <Checkbox
                          checked={emailOrder}
                          onChange={(e) => setEmailOrder(e.target.checked)}
                          name={"emailOrder"}
                        />
                      }
                    />
                  )}
                  {!props.orderId && (
                    <FormControlLabel
                      label="force on duplicate?"
                      control={
                        <Checkbox
                          checked={forceCreate}
                          onChange={(e) => setForceCreate(e.target.checked)}
                          name={"force Create"}
                        />
                      }
                    />
                  )}
                </Stack>
                <Stack direction="row" gap={1}>
                  {orderObj?.invoice?.id && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        showModal(HistoryModal, {
                          invoiceId: orderObj?.invoice?.id,
                        })
                      }
                    >
                      History
                    </Button>
                  )}

                  <Button
                    onClick={() => setConfirmCloseOpen(true)}
                    variant="outlined"
                    size="small"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isSaving || orderLoading}
                    onClick={saveChanges}
                    color="primary"
                    variant="contained"
                    data-testid="saveButton"
                    size="small"
                  >
                    {props.orderId ? "Save" : "Create"}
                  </Button>
                  {props.orderId && (
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ maxWidth: 20 }}
                      onClick={() => setConfirmDeleteOpen(true)}
                    >
                      Delete
                    </Button>
                  )}

                  <ConfirmDialog
                    open={confirmDeleteOpen}
                    setOpen={setConfirmDeleteOpen}
                    onConfirm={() => {
                      deletetOrder(props.orderId).then((res) => {
                        props.refreshTable(true);
                        props.onClose();
                      });
                    }}
                  >
                    {`Are you sure you want to delete Order #${props.orderId}?`}
                  </ConfirmDialog>
                  <ConfirmDialog
                    title="Confirm close order"
                    open={confirmCloseOpen}
                    setOpen={setConfirmCloseOpen}
                    onConfirm={() => props.onClose()}
                  >
                    <Typography>
                      Are you sure you want to close this window?
                    </Typography>
                  </ConfirmDialog>
                </Stack>
              </Stack>
            </DialogActions>
          </Box>
          <AddItemModalQty
            itemToAddW={itemToAdd}
            setItemToAdd={setItemToAdd}
            addItem={addItem}
            key={itemToAdd?.index || 1}
          />
        </Box>
        <Box>
          <Divider orientation="vertical" />
        </Box>
        <Box
          sx={{
            minWidth: "250px",
          }}
        >
          <NotesModal account={account} />
        </Box>
      </Box>
    </Dialog>
  );
};

export default OrderItemsModal;

function AddItemModalQty({ itemToAddW, setItemToAdd, addItem }) {
  const qtyRef = useRef(null);
  const { itemToAdd, index } = itemToAddW || {};
  const [qty, setQty] = useState("");
  const [errors, setErrors] = useState({});
  const [customName, setCustomName] = useState(itemToAdd?.item?.name);

  useEffect(() => {
    if (itemToAdd) {
      setQty("");
      setTimeout(() => {
        qtyRef?.current.focus();
      }, 300);
    }
  }, [itemToAdd]);

  if (!itemToAdd) {
    return <></>;
  }
  const isCustom = itemToAdd.custom == true;

  function changeQty(e) {
    setQty(e.target.value);
  }

  function valOrOne(qty) {
    const newQty = parseFloat(qty);
    if (isNaN(newQty)) {
      return 1;
    }
    if (newQty < (itemToAdd.item?.sys_min_qty || 1)) {
      return 1;
    }
    return newQty;
  }

  function validateQty(qty) {
    let newQty = qty;
    newQty = parseFloat(qty);
    if (isNaN(newQty)) {
      setErrors({ qty: `${qty} is not a valid number` });
      return false;
    }
    if (newQty % (itemToAdd.item?.sys_min_qty || 1) != 0) {
      setErrors({
        qty: `${newQty} is not a valid qty, qty should be devidable by ${
          itemToAdd.item?.sys_min_qty || 1
        }`,
      });
      return false;
    }
    if (newQty < itemToAdd.item.order_qty) {
      setErrors({
        qty: `${newQty} is not a valid qty, qty should be >= ${itemToAdd.item.order_qty}`,
      });
      return false;
    }
    return true;
  }

  function validateCustomName() {
    setErrors({});
    if (isCustom) {
      if (!customName) {
        setErrors({ "custom name": "custom name can not be empty" });
        return false;
      }
      if (customName == itemToAdd?.item?.name) {
        setErrors({
          "custom name": "custom name can not be same as item s name",
        });
        return false;
      }
    }
    return true;
  }

  function addItemFunc() {
    if (!validateQty(qty) || !validateCustomName()) {
      return;
    }
    addItem(itemToAdd, index, parseFloat(qty), customName, isCustom);
    setItemToAdd(null);
    setErrors({});
    setCustomName("");
  }

  //////

  return (
    <Dialog
      open={itemToAdd != null}
      onClose={() => {
        setItemToAdd(null);
        setQty();
      }}
      aria-labelledby="confirm-dialog"
    >
      <HotkeysProvider initiallyActiveScopes={["addModal"]}>
        <DialogTitle id="confirm-dialog">
          Add Item {isCustom ? "(custom)" : ""}
        </DialogTitle>
        <DialogContent>
          {Object.keys(errors).map((key) => (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {key}: {errors[key]}
            </Alert>
          ))}
          <Typography>
            {itemToAdd.item.name} ({itemToAdd.item.sku})
          </Typography>
          <Grid container marginTop={1}>
            <Grid item md={4}>
              <Typography marginTop={1}>Order QTY</Typography>
              <small>
                {">="}
                {itemToAdd.item.order_qty}
              </small>
            </Grid>
            <Grid item md={8}>
              <Stack direction={"row"}>
                <TextField
                  fullWidth
                  inputRef={qtyRef}
                  onChange={(e) => changeQty(e)}
                  value={qty}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      addItemFunc();
                      e.preventDefault();
                    }
                  }}
                />
                <Stack marginLeft={1}>
                  <Button
                    variant="outlined"
                    sx={{
                      maxHeight: "19px",
                      maxWidth: "15px",
                      minWidth: "5px",
                      marginBottom: "2px",
                      fontSize: "20px",
                      fontWeight: 400,
                    }}
                    onClick={(e) =>
                      changeQty({
                        target: {
                          value:
                            valOrOne(qty) + (itemToAdd.item?.sys_min_qty || 1),
                        },
                      })
                    }
                  >
                    +
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      maxHeight: "19px",
                      maxWidth: "15px",
                      minWidth: "5px",
                      fontSize: "25px",
                      fontWeight: 400,
                    }}
                    onClick={(e) =>
                      changeQty({
                        target: {
                          value:
                            valOrOne(qty) - (itemToAdd.item?.sys_min_qty || 1),
                        },
                      })
                    }
                    disabled={qty === (itemToAdd.item?.sys_min_qty || 1)}
                  >
                    -
                  </Button>
                </Stack>
                <Typography
                  fontSize={24}
                  fontWeight={500}
                  sx={{ marginLeft: 2 }}
                >
                  {itemToAdd.item.sold_by}
                </Typography>
              </Stack>
            </Grid>
            {isCustom && (
              <>
                <Grid item md={3}>
                  <Typography marginTop={1}>Custom name</Typography>
                </Grid>
                <Grid item md={9}>
                  <TextField
                    fullWidth
                    onChange={(e) => setCustomName(e.target.value)}
                    value={customName || itemToAdd.item.name}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setItemToAdd(null);
              setQty();
            }}
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => addItemFunc()}
            color={"success"}
          >
            Add Item
          </Button>
        </DialogActions>
      </HotkeysProvider>
    </Dialog>
  );
}
